import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import Input from "./form/Input";

export default function TableHeader({className, title, onSearch, filter, children}: {
    className?: string,
    title?: React.ReactNode,
    onSearch?: (search: string) => void,
    filter?: React.ReactNode,
    children?: React.ReactNode
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("h-16 border-b border-neutral-100 flex items-center justify-between px-8", className)}>
            <div className="flex items-center gap-8">
                {title && <h2 className="heading-m">{title}</h2>}
                {onSearch && <Input type="search" placeholder={t("search")} onSearch={onSearch} />}
                {filter}
            </div>
            {children}
        </div>
    )
}
import { ChoiceProps } from "@/js/types";
import { useGlissadeChoice } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";
import Checkbox from "../Checkbox";
import Error from "../Error";

export default function CheckboxInput({
    className,
    children,
    ...props
}: ChoiceProps) {
    const {error, ...glissade} = useGlissadeChoice(props);

    return (
        <div className={classNames("flex flex-col gap-2", className)}>
            <label className="flex gap-2.5 items-center body-m cursor-pointer select-none w-max">
                <input className="absolute size-0 peer" type="checkbox" {...glissade} />
                <Checkbox highlight={error !== undefined ? "error" : undefined} />
                {children}
            </label>
            <Error noContext>{error}</Error>
        </div>
    )
}
import { MaybePromise, RequiredBy } from "@/js/types";
import { createRequiredContext } from "@enymo/react-better-context";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/form/Button";
import Popup from "../components/popup/Popup";
import PopupActions from "../components/popup/PopupActions";
import PopupContent from "../components/popup/PopupContent";

export interface Config {
    type?: "info" | "confirm",
    variant?: "normal" | "danger",
    title: React.ReactNode,
    text: React.ReactNode,
    confirm?: React.ReactNode,
    cancel?: React.ReactNode,
    dismissable?: boolean,
    onConfirm?: () => MaybePromise<Config | void>,
    onCancel?: () => MaybePromise<Config | void>
};

interface ConfigState extends RequiredBy<Config, "type" | "variant" | "confirm" | "cancel" | "dismissable"> {
    resolve: (result: boolean) => void
}

type Show = (config: Config) => Promise<boolean>
const [Provider, usePopup] = createRequiredContext<Show>("PopupProvider must be present in component tree");

export { usePopup };
export default function PopupProvider({children}: {
    children: React.ReactNode
}) {
    const {t} = useTranslation();
    const [config, setConfig] = useState<ConfigState | null>(null);

    const show = useCallback<Show>(({
        type = "info",
        variant = "normal",
        confirm = t("confirm"),
        cancel = t("cancel"),
        dismissable = false,
        ...rest
    }) => new Promise<boolean>(resolve => setConfig({
        type,
        variant,
        confirm,
        cancel,
        dismissable,
        resolve,
        ...rest,
    })), [setConfig]);

    const handleClick = async (result: boolean) => {
        assertNotNull(config);
        const next = await (result ? config.onConfirm?.() : config.onCancel?.());
        if (next !== undefined) {
            const {
                type = "info",
                variant = "normal",
                confirm = t("confirm"),
                cancel = t("cancel"),
                dismissable = false,
                ...rest
            } = next;
            setConfig({type, variant, confirm, cancel, dismissable, ...rest, resolve: config.resolve});
        }
        else {
            config?.resolve(result);
            setConfig(null);
        }
    }

    return <>
        <Provider value={show}>
            {children}
        </Provider>
        {config && (
            <Popup onBackgroundClick={config.dismissable ? () => handleClick(false) : undefined}>
                <PopupContent className="w-sm" title={config.title}>
                    <p className="body-m">{config.text}</p>
                </PopupContent>
                <PopupActions align={config.type === "info" ? "end" : "space"}>
                    {config.type === "confirm" && (
                        <Button variant="secondary" onClick={() => handleClick(false)}>{config.cancel}</Button>
                    )}
                    <Button variant={config.variant === "normal" ? "primary" : "danger"} onClick={() => handleClick(true)}>{config.confirm}</Button>
                </PopupActions>
            </Popup>
        )}
    </>
}
import { returnFalse } from "@/js/common";
import { useCategories, useModels, useVideos, VideoUpdate } from "@/js/resources";
import { Status, status } from "@/js/types";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router";
import VideosComponent, { VideosSortBy } from "../components/pages/Videos";
import usePaginator from "../hooks/PaginatorHook";
import { useSanitizedNumber, useSanitizedNumbers, useSanitizedString } from "../hooks/SanitizedParamHook";

const categoryParams = {
    locale: "de"
}

export default function Videos() {
    const {page, q, changePage, search, sortBy, changeSortBy} = usePaginator<VideosSortBy["name"]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, {loading: categoriesLoading}] = useCategories({params: categoryParams});
    const [models, {loading: modelsLoading}] = useModels();

    const modelFilter = useSanitizedNumbers(searchParams.getAll("models[]"), {min: 0}, undefined, [searchParams]);
    const statusFilter = useSanitizedString(searchParams.get("status"), status as unknown as Status[], null);
    const categoryFilter = useSanitizedNumber(searchParams.get("category"), {min: 0}, null)

    const [videos, {meta, store, update, destroy, refresh}] = useVideos({
        onCreated: returnFalse,
        params: useMemo(() => ({
            with: ["translations", "category", "models", "featured_preview", "unpublished"],
            q,
            page,
            sort_by: (sortBy ?? {name: "id", direction: "desc"}) as any,
            models: modelFilter,
            category: categoryFilter ?? undefined,
            status: statusFilter ?? undefined
        }), [q, page, sortBy, modelFilter, categoryFilter, statusFilter])
    });

    const handleUpdateSearchParam = (param: string) => (value: string | number | number[] | null) => {
        if (value === null) {
            searchParams.delete(param);
        }
        else if (Array.isArray(value)) {
            searchParams.delete(`${param}[]`);
            for (const single of value) {
                searchParams.append(`${param}[]`, single.toString());
            }
        }
        else {
            searchParams.set(param, value.toString());
        }
        setSearchParams(searchParams);
    }

    const handleStore = async (data: Omit<VideoUpdate, "rating" | "favorite" | "watch_later" | "view">) => {
        await store(data);
        await refresh();
    }

    if (!meta) {
        return null;
    }

    return (
        <VideosComponent
            videos={videos}
            itemsPerPage={meta.per_page}
            onChangePage={changePage}
            onChangeSortBy={changeSortBy}
            onSearch={search}
            page={page}
            sortBy={sortBy ?? {name: "id", direction: "desc"}}
            categories={categoriesLoading ? undefined : categories}
            models={modelsLoading ? undefined : models}
            onCreateVideo={handleStore}
            onUpdateVideo={update}
            onDeleteVideo={destroy}
            totalItems={meta.total}
            filterModels={modelFilter}
            filterCategory={categoryFilter}
            filterStatus={statusFilter}
            onChangeFilterCategory={handleUpdateSearchParam("category")}
            onChangeFilterModels={handleUpdateSearchParam("models")}
            onChangeFilterStatus={handleUpdateSearchParam("status")}
        />
    )
}
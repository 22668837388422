import Loader from "@/js/components/Loader";
import classNames from "classnames";
import React from "react";

export default function TableProgress({className, loading, children}: {
    className?: string,
    loading?: boolean,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("flex items-center gap-2 body-m-md text-text-600", className)}>
            {loading && <Loader className="w-5 fill-neutral-600" />}
            {children}
        </div>
    )
}
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Error from "../Error";
import FileInput, { FileInputRef } from "../FileInput";
import Button from "./Button";

export default function ImageInput({
    className,
    imageClassName,
    src,
    value: externalValue,
    onChange: externalOnChange,
    name,
    options,
    label,
    error: errorProp
}: {
    className?: string,
    imageClassName?: string,
    src?: string,
    value?: File,
    onChange?: (file: File) => void,
    name?: string,
    options?: RegisterOptions,
    label?: string,
    error?: string
}) {
    const {t} = useTranslation();
    const ref = useRef<FileInputRef>(null);
    const {value, onChange, error} = useHybridInput({externalValue, externalOnChange, name, options});
    const [objectUrl, setObjectUrl] = useState<string>();

    useEffect(() => {
        if (value) {
            const objectUrl = URL.createObjectURL(value);
            setObjectUrl(objectUrl);
            return () => {
                setObjectUrl(undefined);
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, [value, setObjectUrl]);

    return (
        <div className={classNames("flex flex-col gap-4", className)}>
            <label className="heading-s">{label}</label>
            <div className="flex-1 justify-between flex flex-col items-start gap-2.5">
                <img className={imageClassName} src={objectUrl ?? src} />
                <Button variant="secondary" onClick={() => ref.current?.open()}>{t("upload")}</Button>
            </div>
            <Error>{errorProp ?? error?.message}</Error>
            <FileInput ref={ref} accept="image/*" onSelected={([file]) => onChange(file)} />
        </div>
    )
}
import { byId, mapObject } from "@/js/common";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { Model, ModelUpdate } from "@/js/resources";
import { genitalAreaTypes, Locale, locales, nationalities, preferences, zodiacs } from "@/js/types";
import { Form, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import { AxiosError } from "axios";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { getDescription } from "../../common";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Pagination from "../Pagination";
import Table, { SortBy } from "../Table";
import TableHeader from "../TableHeader";
import Tabs from "../Tabs";
import Button from "../form/Button";
import ImageInput from "../form/ImageInput";
import Input from "../form/Input";
import KeywordInput from "../form/KeywordInput";
import SelectMultiple from "../form/SelectMultiple";
import Popup from "../popup/Popup";
import PopupActions from "../popup/PopupActions";
import PopupContent from "../popup/PopupContent";

export type ModelsSortBy = SortBy<"id" | "first_name" | "last_name" | "created_at">;

export default function Models({
    models,
    onUpdate,
    onCreate,
    onDelete,
    sortBy,
    onChangeSortBy,
    onSearch,
    ...props
}: {
    models?: Omit<Model, "description" | "bio" | "likes" | "dislikes" | "rating" | "favorite">[],
    onUpdate: (id: number, data: Partial<ModelUpdate>) => void | Promise<void>,
    onCreate: (data: ModelUpdate) => void | Promise<void>,
    onDelete: (id: number) => void | Promise<void>,
    page: number,
    itemsPerPage: number,
    totalItems: number,
    onChangePage: (page: number) => void,
    onSearch: (search: string) => void,
    sortBy: ModelsSortBy,
    onChangeSortBy: (sortBy: ModelsSortBy) => void
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();
    const popup = usePopup();

    const [editModelId, setEditModelId] = useState<number | "create" | null>(null);
    const editModel = useMemo(() => models?.find(byId(editModelId)), [models, editModelId]);
    const [editTranslation, setEditTranslation] = useState<Locale>("de");
    const form = useForm<ModelUpdate>();

    const handleEditModel = (id: number) => {
        const model = requireNotNull(models?.find(byId(id)), "unable to find model");
        setFormValues(form, {
            translations: model.translations,
            age: model.age,
            zodiac: model.zodiac,
            first_name: model.first_name,
            last_name: model.last_name,
            gender: model.gender,
            height: model.height,
            weight: model.weight,
            cup_size: model.cup_size,
            nationality: model.nationality,
            genital_area: model.genital_area,
            preferences: model.preferences
        });
        setEditModelId(id);
    }

    const handleSubmit: SubmitHandler<ModelUpdate> = async data => {
        assertNotNull(editModelId);
        const sanitized: ModelUpdate = {
            ...data,
            translations: mapObject(data.translations, translation => translation?.bio && translation.description ? translation : null)
        }
        await (editModelId === "create" ? onCreate(sanitized) : onUpdate(editModelId, sanitized));
        handleClosePopup();
    }

    const handleDeleteModel = (id: number) => {
        const {first_name, last_name} = requireNotNull(models?.find(byId(id)), "model not found");
        popup({
            title: t("models.delete"),
            text: t("models.delete.text", {name: last_name ? `${first_name} ${last_name}` : first_name}),
            confirm: t("models.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: async () => {
                try {
                    await onDelete(id);
                }
                catch (e) {
                    if (e instanceof AxiosError && e.status === 409) {
                        return {
                            title: t("models.delete.error"),
                            text: t("models.delete.error.text"),
                            confirm: t("ok"),
                            variant: "danger"
                        };
                    }
                    else {
                        throw e;
                    }
                }
            }
        });
    }

    const handleClosePopup = () => {
        form.reset();
        setEditModelId(null);
        setEditTranslation("de");
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            children: t("models")
        }]} />
        <div className="flex-1 flex flex-col overflow-y-auto">
            <TableHeader onSearch={onSearch}>
                <Button variant="primary" onClick={() => setEditModelId("create")}>{t("models.add")}</Button>
            </TableHeader>
            <Pagination borderBottom {...props} />
            <div className="flex-1 overflow-x-auto">
                {models && (
                    <PhotoProvider>
                        <Table
                            sortBy={sortBy}
                            onChangeSortBy={onChangeSortBy}
                            head={[{
                                label: t("models.preview")
                            }, {
                                label: t("models.alias")
                            }, {
                                name: "id",
                                label: t("id")
                            }, {
                                name: "first_name",
                                label: t("models.firstName")
                            }, {
                                name: "last_name",
                                label: t("models.lastName")
                            }, {
                                name: "created_at",
                                label: t("createdAt"),
                            }, {
                                fill: true 
                            }]}
                            rows={models.map(({id, translations, age, first_name, last_name, preview, created_at}) => ({
                                id,
                                data: [{
                                    children: (
                                        <PhotoView {...preview}>
                                            <img className="h-12 aspect-card w-auto max-w-none object-cover skeleton" {...preview} id={undefined} />
                                        </PhotoView>
                                    )
                                }, {
                                    children: `${first_name} (${age}) ${getDescription(translations)}`
                                }, {
                                    children: id
                                }, {
                                    children: first_name
                                }, {
                                    children: last_name
                                }, {
                                    children: dayjs(created_at).format("L")
                                }, {
                                    children: (
                                        <div className="flex justify-end">
                                            <DotsDropdown items={[{
                                                onClick: () => handleEditModel(id),
                                                children: t("models.settings")
                                            }, {
                                                onClick: () => handleDeleteModel(id),
                                                variant: "danger",
                                                children: t("models.delete")
                                            }]} />
                                        </div>
                                    )
                                }]
                            }))}
                        />
                    </PhotoProvider>
                )}
            </div>
        </div>
        <Pagination {...props} />
        {editModelId !== null && (
            <Popup>
                <Form form={form} onSubmit={handleSubmit}>
                    <PopupContent title={editModelId === "create" ? t("models.create") : t("models.settings")} className="w-xl">
                        <div className="grid grid-cols-3 gap-x-3 gap-y-6">
                            <Input name="first_name" label={t("models.firstName")} options={{
                                required: t("models.firstName.required")
                            }} />
                            <Input name="last_name" label={t("models.lastName")} />
                            <Input type="select" name="gender" label={t("models.gender")} choices={[{
                                value: "female",
                                label: t("gender.female")
                            }, {
                                value: "male",
                                label: t("gender.male")
                            }]} />
                            <Input type="number" name="age" label={t("models.age")} options={{
                                valueAsNumber: true,
                                required: t("models.age.required"),
                                min: {
                                    value: 18,
                                    message: t("models.age.range")
                                }
                            }} />
                            <Input type="number" name="height" label={t("models.height")} options={{
                                valueAsNumber: true,
                                required: t("models.height.required")
                            }} />
                            <Input type="number" name="weight" label={t("models.weight")} options={{
                                valueAsNumber: true,
                                required: t("models.weight.required")
                            }} />
                            <Input name="cup_size" label={t("models.cupSize")} options={{
                                required: t("models.cupSize.required"),
                                pattern: {
                                    value: /(?:[6-9]|1[0-3])(?:0|5) (?:AA|ZZZ?|[A-Z])/,
                                    message: t("models.cupSize.pattern")
                                }
                            }} />
                            <Input type="select" name="zodiac" label={t("models.zodiac")} placeholder={t("select.placeholder")} options={{
                                required: t("models.zodiac.required")
                            }} choices={zodiacs.map(zodiac => ({
                                value: zodiac,
                                label: t(`zodiac.${zodiac}`)
                            }))} />
                            <Input type="select" name="nationality" label={t("models.nationality")} placeholder={t("select.placeholder")} options={{
                                required: t("models.nationality.required")
                            }} choices={nationalities.map(nationality => ({
                                value: nationality,
                                label: t(`nationality.${nationality}`)
                            }))} />
                            <Input type="select" name="genital_area" label={t("models.genitalArea")} placeholder={t("select.placeholder")} options={{
                                required: t("models.genitalArea.required")
                            }} choices={genitalAreaTypes.map(genitalAreaType => ({
                                value: genitalAreaType,
                                label: t(`genitalArea.${genitalAreaType}`)
                            }))} />
                            <SelectMultiple className="col-span-2" name="preferences" label={t("models.preferences")} choices={preferences.map(preference => ({
                                label: t(`preferences.${preference}`),
                                value: preference
                            }))} options={{
                                required: t("models.preference.required")
                            }} />
                        </div>
                        <div className="my-9 flex gap-9">
                            <ImageInput className="flex-1" imageClassName="h-28 aspect-card w-auto max-w-none object-cover" src={editModel?.preview?.src} name="preview" label={t("models.preview")} options={{
                                required: editModelId === "create" && t("models.preview.required")
                            }} />
                            <ImageInput className="flex-1" imageClassName="size-28 rounded-full object-cover" src={editModel?.avatar?.src} name="avatar" label={t("models.avatar")} options={{
                                required: editModelId === "create" && t("models.avatar.required")
                            }} />
                        </div>
                        <Tabs className="mb-7" value={editTranslation} onChange={setEditTranslation} items={locales.map(locale => ({
                            value: locale,
                            children: locale.toUpperCase()
                        }))} />
                        {locales.map(locale => (
                            <div key={locale} className={classNames("flex flex-col gap-6", {"hidden": locale !== editTranslation})}>
                                <Input name={`translations.${locale}.description`} label={t("models.alias")} options={{
                                    required: locale === "de" ? t("translations.required") : false
                                }} />
                                <Input type="textarea" rows={6} name={`translations.${locale}.bio`} label={t("models.description")} options={{
                                    required: locale === "de" ? t("translations.required") : false
                                }} />
                                <KeywordInput name={`translations.${locale}.keywords`} label={t("models.keywords")} />
                            </div>
                        ))}
                    </PopupContent>
                    <PopupActions>
                        <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                        <Button variant="primary" submit>{t("save")}</Button>
                    </PopupActions>
                </Form>
            </Popup>
        )}
    </>
}
import { byId } from "@/js/common";
import { sortLinkedList } from "@/js/linkedList";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { VideoPreview } from "@/js/resources";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import FileInput, { FileInputRef } from "../FileInput";
import Button from "../form/Button";
import StatusTag from "../StatusTag";
import Table, { DndHandler } from "../Table";
import TableHeader from "../TableHeader";
import TableProgress from "../TableProgress";

export default function VideoPreviews({title, previews, onUpload, onDelete, onDragDrop, uploads}: {
    title?: string,
    previews?: VideoPreview[],
    onUpload: (files: File[]) => void,
    onDelete: (id: number) => Promise<void> | void,
    onDragDrop: DndHandler<number>,
    uploads: {
        id: number,
        filename: string,
        progress: number
    }[]
}) {
    const {t, i18n} = useTranslation();
    const dayjs = useDayjs();
    const withPopup = usePopup();
    const ref = useRef<FileInputRef>(null);

    const sortedPreviews = useMemo(() => sortLinkedList(previews ?? []), [previews]);

    const handleDelete = (id: number) => {
        const preview = requireNotNull(previews?.find(byId(id)), "preview not found");
        withPopup({
            title: t("videos.previews.delete"),
            text: t("videos.previews.delete.text", {filename: preview.filename}),
            confirm: t("videos.previews.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: () => onDelete(id)
        });
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            to: "/videos",
            children: t("videos")
        }, {
            children: title
        }]} />
        <div className="flex flex-col flex-1 overflow-y-auto">
            <TableHeader title={t("videos.previews")}>
                <Button variant="primary" onClick={() => ref.current?.open()}>{t("videos.previews.upload")}</Button>
            </TableHeader>
            <div className="flex-1 overflow-x-auto">
                <PhotoProvider>
                    <Table
                        head={[{
                            label: t("videos.previews.image")
                        }, {
                            label: t("videos.previews.filename")
                        }, {
                            label: t("videos.previews.resolution")
                        }, {
                            label: t("videos.previews.createdAt"),
                            colSpan: 3
                        }, {
                            fill: true
                        }]}
                        rows={[
                            ...sortedPreviews.map(({id, thumbnail, content, filename, created_at}, index) => {
                                assertNotNull(content, "content error");
                                return {
                                    id,
                                    data: [{
                                        children: (
                                            <PhotoView {...content}>
                                                <img className="h-20 w-auto max-w-none object-cover aspect-video skeleton" {...thumbnail} />
                                            </PhotoView>
                                        )
                                    }, {
                                        children: filename
                                    }, {
                                        children: t("resolution.px", {
                                            width: content.width.toLocaleString(i18n.language),
                                            height: content.height.toLocaleString(i18n.language)
                                        })
                                    }, {
                                        children: dayjs(created_at).format("L")
                                    }, {
                                        children: index === 0 && <StatusTag variant="primary">{t("videos.previews.thumbnail")}</StatusTag>,
                                        colSpan: 2
                                    }, {
                                        children: (
                                            <div className="flex justify-end">
                                                <DotsDropdown items={[{
                                                    onClick: () => handleDelete(id),
                                                    variant: "danger",
                                                    children: t("videos.previews.delete")
                                                }]} />
                                            </div>
                                        )
                                    }]
                                }
                            }),
                            ...uploads.map(({id, filename, progress}) => ({
                                id: `upload-${id}` as any, // Not used for dnd, therefore type can be ignored. Otherwise problems with dnd handler
                                disableDnd: true,
                                data: [{
                                    children: (
                                        <div className="h-20 aspect-video skeleton" />
                                    )
                                }, {
                                    children: filename
                                }, {
                                    children: "-"
                                }, {
                                    children: "-",
                                    colSpan: 2
                                }, {
                                    children: <TableProgress loading>{t("videos.previews.uploadProgress", {progress: Math.round(progress * 100)})}</TableProgress>,
                                    colSpan: 2
                                }]
                            }))
                        ]}
                        onDragDrop={onDragDrop}
                    />
                </PhotoProvider>
            </div>
        </div>
        <FileInput ref={ref} accept="image/*" multiple onSelected={onUpload} />
    </>
}
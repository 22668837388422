import { byId } from "@/js/common";
import { sortLinkedList } from "@/js/linkedList";
import LinebreakText from "@/js/main/components/LinebreakText";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { Plan } from "@/js/resources";
import CopyIcon from "@/svg/copy-regular.svg?react";
import { CheckboxList } from "@enymo/glissade";
import { Form, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Error from "../Error";
import Button from "../form/Button";
import CheckboxInput from "../form/CheckboxInput";
import Input from "../form/Input";
import InputFrame from "../form/InputFrame";
import Popup from "../popup/Popup";
import PopupActions from "../popup/PopupActions";
import PopupContent from "../popup/PopupContent";
import StatusTag from "../StatusTag";
import Table, { DndHandler } from "../Table";
import TableHeader from "../TableHeader";

type Submit = Pick<Plan, "name" | "visibility" | "period" | "price" | "trial_days" | "trial_price" | "features"> & {
    is_active: "true" | "false"
}

export type NewPlan = Omit<Plan, "id" | "sid" | "previous_id" | "users_count" | "created_at" | "deleted_at" | "highlight">;

export default function Plans({plans, onCreate, onUpdate, onDelete, onRestore, onCopy, onDragDrop}: {
    plans?: Plan[],
    onCreate: (data: NewPlan) => void | Promise<void>,
    onUpdate: (id: number, data: Partial<Plan>, force: boolean) => Promise<boolean>,
    onDelete: (id: number) => void | Promise<void>,
    onRestore: (id: number) => void | Promise<void>
    onCopy: (sid: string) => void,
    onDragDrop: DndHandler<number>
}) {
    const {t, i18n} = useTranslation();
    const dayjs = useDayjs();
    const popup = usePopup();
    const sortedPlans = useMemo(() => [
        ...sortLinkedList(plans?.filter(({visibility, deleted_at}) => visibility === "public" && deleted_at === null) ?? []),
        ...(plans?.filter(({visibility, deleted_at, users_count}) => visibility === "unlisted" || (deleted_at !== null && users_count > 0)) ?? [])
    ], [plans]);
    const form = useForm<Submit>();

    const [editPlanId, setEditPlanId] = useState<number | "create" | null>(null);

    const handleEdit = (id: number) => () => {
        const {name, is_active, visibility, period, price, trial_days, trial_price, features} = requireNotNull(plans?.find(byId(id)), "unable to find plan");
        setFormValues(form, {
            name,
            is_active: is_active ? "true" : "false",
            visibility,
            period,
            price: price / 100,
            trial_days,
            trial_price: trial_price !== null ? trial_price / 100 : null,
            features
        });
        setEditPlanId(id);
    }

    const handleDelete = (id: number) => () => {
        const plan = requireNotNull(plans?.find(byId(id)), "unable to find plan");
        popup({
            title: t("plans.delete"),
            text: <LinebreakText>{t("plans.delete.text", {name: plan.name})}</LinebreakText>,
            confirm: t("plans.delete"),
            variant: "danger",
            type: "confirm",
            onConfirm: () => onDelete(id)
        });
    }

    const handleRestore = (id: number) => () => {
        const plan = requireNotNull(plans?.find(byId(id)), "unable to find plan");
        popup({
            title: t("plans.restore"),
            text: t("plans.restore.text", {name: plan.name}),
            confirm: t("plans.restore"),
            type: "confirm",
            onConfirm: () => onRestore(id)
        })
    }

    const handleClosePopup = () => {
        setEditPlanId(null);
        form.reset();
    }

    const handleSubmit: SubmitHandler<Submit> = async submit => {
        assertNotNull(editPlanId);
        const data: NewPlan = {
            ...submit,
            is_active: submit.is_active === "true",
            price: Math.round(submit.price * 100),
            trial_price: submit.trial_price ? Math.round(submit.trial_price * 100) : null
        }
        if (editPlanId === "create") {
            await onCreate(data);
        }
        else {
            if (!await onUpdate(editPlanId, data, false)) {
                popup({
                    title: t("plans.conflict.title"),
                    text: t("plans.conflict.text"),
                    cancel: t("cancel"),
                    confirm: t("plans.conflict.confirm"),
                    type: "confirm",
                    variant: "danger",
                    onConfirm: async () => void await onUpdate(editPlanId, data, true)
                })
            }
        }
        handleClosePopup();
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            children: t("plans")
        }]} />
        <TableHeader>
            <Button variant="primary" onClick={() => setEditPlanId("create")}>{t("plans.add")}</Button>
        </TableHeader>
        <div className="flex-1 overflow-x-auto">
            <Table
                head={[{
                    label: t("plans.name")
                }, {
                    label: t("id")
                }, {
                    label: t("status")
                }, {
                    label: t("plans.activeUsers")
                }, {
                    label: t("plans.visibility")
                }, {
                    label: t("plans.price")
                }, {
                    label: t("plans.period")
                }, {
                    label: t("plans.trialPeriod")
                }, {
                    label: t("plans.trialPeriodPrice")
                }, {
                    label: t("createdAt"),
                    colSpan: 2
                }, {
                    fill: true
                }]}
                rows={sortedPlans.map(({id, sid, name, is_active, users_count, visibility, price, period, trial_days, trial_price, deleted_at, created_at, highlight}) => ({
                    id,
                    disableDnd: visibility === "unlisted" || deleted_at !== null,
                    data: [{
                        children: name
                    }, {
                        children: id
                    }, {
                        children: (
                            <div className="flex">
                                {deleted_at !== null ? (
                                    <StatusTag variant="error">{t("plans.status.deleted")}</StatusTag>
                                ) : is_active ? (
                                    <StatusTag variant="success">{t("plans.status.active")}</StatusTag>
                                ) : (
                                    <StatusTag variant="neutral">{t("status.plan.inactive")}</StatusTag>
                                )}
                            </div>
                        )
                    }, {
                        children: users_count
                    }, {
                        children: (
                            <div className="flex items-center gap-2.5">
                                {visibility === "public" ? (
                                    <StatusTag variant="primary">{t("plans.visibility.public")}</StatusTag>
                                ) : <>
                                    <StatusTag variant="neutral">{t("plans.visibility.unlisted")}</StatusTag>
                                    <button type="button" onClick={() => onCopy(sid)}>
                                        <CopyIcon className="fill-neutral-400 size-3.5" />
                                    </button>
                                </>}
                            </div>
                        )
                    }, {
                        children: `${(price / 100).toLocaleString(i18n.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} €`
                    }, {
                        children: t(`plans.period.${period}`)
                    }, {
                        children: trial_days === null ? "-" : t("plans.trialPeriod.days", {days: trial_days})
                    }, {
                        children: trial_price === null ? "-" : `${(trial_price / 100).toLocaleString(i18n.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} €`
                    }, {
                        children: dayjs(created_at).format("L")
                    }, {
                        children: highlight && (
                            <StatusTag variant="primary">{t("plans.highlight")}</StatusTag>
                        )
                    }, {
                        children: (
                            <div className="flex justify-end">
                                <DotsDropdown items={[{
                                    onClick: handleEdit(id),
                                    children: t("settings")
                                }, {
                                    onClick: () => onUpdate(id, {highlight: !highlight}, false),
                                    children: highlight ? t("plans.removeHighlight") : t("plans.highlight")
                                }, deleted_at === null ? {
                                    variant: "danger",
                                    onClick: handleDelete(id),
                                    children: t("plans.delete")
                                } : {
                                    onClick: handleRestore(id),
                                    children: t("plans.restore")
                                }]} />
                            </div>
                        )
                    }]
                }))}
                onDragDrop={onDragDrop}
            />
        </div>
        {editPlanId !== null && (
            <Popup>
                <Form form={form} onSubmit={handleSubmit}>
                    <PopupContent title={t("settings")} className="w-xl">
                        <div className="flex flex-col gap-6">
                            <Input name="name" label={t("plans.name")} options={{
                                required: t("plans.name.required")
                            }} />
                            <div className="flex gap-3">
                                <Input className="flex-1" name="is_active" type="select" label={t("status")} choices={[{
                                    label: t("active"),
                                    value: "true"
                                }, {
                                    label: t("inactive"),
                                    value: "false"
                                }]} />
                                <Input className="flex-1" name="visibility" type="select" label={t("plans.visibility")} choices={[{
                                    label: t("plans.visibility.public"),
                                    value: "public"
                                }, {
                                    label: t("plans.visibility.unlisted"),
                                    value: "unlisted"
                                }]} />
                            </div>
                            <div className="flex gap-3">
                                <Input className="flex-1" name="period" type="select" label={t("plans.period")} choices={[{
                                    label: t("plans.period.monthly"),
                                    value: "monthly"
                                }, {
                                    label: t("plans.period.yearly"),
                                    value: "yearly"
                                }]} />
                                <Input className="flex-1" name="price" type="number" min={0} step={0.01} label={t("plans.price")} options={{
                                    required: t("plans.price.required"),
                                    valueAsNumber: true
                                }} />
                            </div>
                            <div className="flex gap-3">
                                <Input className="flex-1" name="trial_days" type="number" min={0} label={t("plans.trialDays")} options={{
                                    valueAsNumber: true
                                }} placeholder={t("plans.trialDays.placeholder")} />
                                <Input className="flex-1" name="trial_price" type="number" step={0.01} label={t("plans.trialPrice")} options={{
                                    valueAsNumber: true
                                }} />
                            </div> 
                            <InputFrame label={t("plans.features")}>
                                <CheckboxList name="features">
                                    <div className="flex flex-col gap-3">
                                        <CheckboxInput value="downloads">{t("plans.features.downloads")}</CheckboxInput>
                                    </div>
                                    <Error />
                                </CheckboxList>
                            </InputFrame>
                        </div>
                    </PopupContent>
                    <PopupActions>
                        <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                        <Button variant="primary" submit>{t("save")}</Button>
                    </PopupActions>
                </Form>
            </Popup>
        )}
    </>
}
import { resourceDndSorting } from "@/js/dnd";
import { deleteResourceLinkedListItem } from "@/js/linkedList";
import { useBannerImages } from "@/js/resources";
import { Locale, locales } from "@/js/types";
import React, { useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router";
import BannersComponent from "../components/pages/Banners";
import { useSanitizedString } from "../hooks/SanitizedParamHook";

export default function Banners() {
    const nextId = useRef(1);
    const [banners, {store, update, destroy}] = useBannerImages();
    const [searchParams, setSerachParams] = useSearchParams();
    const [uploads, setUploads] = useState<{
        id: number,
        filename: string,
        progress: number,
        src: string
    }[]>([]);
    const pos = useSanitizedString(searchParams.get("pos"), ["top", "bottom"], "top");
    const locale = useSanitizedString(searchParams.get("locale"), locales as unknown as Locale[], "de");

    const filteredBanners = useMemo(() => banners.filter(({position, locale: bannerLocale}) => position === pos && bannerLocale === locale), [banners, pos, locale]);

    const handleUpload = (files: File[]) => {
        for (const file of files) {
            const id = nextId.current++;
            const src = URL.createObjectURL(file);
            setUploads(uploads => [...uploads, {
                id,
                src,
                filename: file.name,
                progress: 0
            }]);
            store({position: pos, locale, file}, "on-success", {
                onUploadProgress: ({progress}) => {
                    if (progress !== undefined) {
                        setUploads(uploads => uploads.map(upload => upload.id === id ? {
                            ...upload,
                            progress
                        }: upload));
                    }
                }
            }).then(() => {
                setUploads(uploads => uploads.filter(upload => upload.id !== id));
                URL.revokeObjectURL(src);
            });
        }
    }

    const handleUpdateSearchParam = (param: string) => (value: string) => {
        searchParams.set(param, value);
        setSerachParams(searchParams);
    }

    if (!pos || !["top", "bottom"].includes(pos)) {
        setSerachParams({pos: "top"});
        return null;
    }

    return (
        <BannersComponent
            images={filteredBanners}
            uploads={uploads}
            locale={locale}
            onChangeLocale={handleUpdateSearchParam("locale")}
            type={pos}
            onChangeType={handleUpdateSearchParam("pos")}
            onDelete={id => deleteResourceLinkedListItem(filteredBanners, id, update, destroy)}
            onDragDrop={resourceDndSorting(filteredBanners, update)}
            onUpload={handleUpload}
        />
    )
}
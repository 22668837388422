import classNames from "classnames";
import React from "react";

export default function PopupContent({className, title, children}: {
    className?: string,
    title?: React.ReactNode,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("px-7 pt-7 pb-10", className)}>
            {title && <h1 className="heading-m mb-8">{title}</h1>}
            {children}
        </div>
    )
}
import LinebreakText from "@/js/main/components/LinebreakText";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { roles, User } from "@/js/resources";
import { Form, setFormValues } from "@enymo/react-form-component";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Filter from "../Filter";
import Button from "../form/Button";
import EmailInput from "../form/EmailInput";
import Input from "../form/Input";
import Pagination from "../Pagination";
import Popup from "../popup/Popup";
import PopupActions from "../popup/PopupActions";
import PopupContent from "../popup/PopupContent";
import StatusTag from "../StatusTag";
import Table, { SortBy } from "../Table";
import TableHeader from "../TableHeader";

export interface Submit {
    email: string,
    password: string,
    role: User["role"]
}

export type UsersSortBy = SortBy<"id" | "email" | "epoch_id" | "role" | "plan" | "created_at">;

export default function Users({
    className,
    users,
    onUserUpdate,
    onUserDelete,
    onSearch,
    sortBy,
    onChangeSortBy,
    filterStatus,
    onChangeFilterStatus,
    filterRole,
    onChangeFilterRole,
    ...props
}: {
    className?: string,
    users?: User[],
    onUserUpdate: (id: number, update: Submit) => void | Promise<void>,
    onUserDelete: (id: number) => void | Promise<void>,
    page: number,
    itemsPerPage: number,
    totalItems: number,
    onChangePage: (page: number) => void,
    onSearch: (search: string) => void,
    sortBy: UsersSortBy,
    onChangeSortBy: (sortBy: UsersSortBy) => void,
    filterStatus: "active" | "inactive" | "cancelled" | "downgrade" | null,
    onChangeFilterStatus: (filterStatus: "active" | "inactive" | "cancelled" | "downgrade" | null) => void,
    filterRole: User["role"] | null,
    onChangeFilterRole: (filterRole: User["role"] | null) => void
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();
    const [editUserId, setEditUserId] = useState<number | null>(null);
    const activeFilters = useMemo(() => [filterStatus, filterRole].reduce((prev, cur) => cur === null ? prev : prev + 1, 0), [filterStatus, filterRole]);
    const withPopup = usePopup();

    const form = useForm<Submit>();

    const handleEditUser = (id: number) => () => {
        const user = requireNotNull(users?.find(user => user.id === id), "user not found");
        setFormValues(form, {
            email: user.email,
            password: "",
            role: user.role
        });
        setEditUserId(id);
    }

    const handleDeleteUser = async (id: number) => {
        const user = requireNotNull(users?.find(user => user.id === id), "Could not find user to delete");
        withPopup({
            type: "confirm",
            variant: "danger",
            confirm: t("users.delete"),
            onConfirm: () => onUserDelete(id),
            title: t("users.delete"),
            text: <LinebreakText>{t(user.plan !== null ? "users.delete.text.active" : "users.delete.text", {user: user.email})}</LinebreakText>
        });
    }

    const handleUpdateUser = async (id: number, data: Submit) => {
        await onUserUpdate(id, data);
        setEditUserId(null);
    }

    return (
        <>
            <Breadcrumbs breadcrumbs={[{children: t("users")}]} />
            <div className="flex-1 flex flex-col overflow-y-auto">
                <TableHeader onSearch={onSearch} filter={
                    <Filter active={activeFilters}>
                        <Input type="select" label={t("users.status")} value={filterStatus ?? ""} onChange={value => onChangeFilterStatus(value || null)} choices={[{
                            label: t("filters.all"),
                            value: ""
                        }, {
                            label: t("user.status.active"),
                            value: "active"
                        }, {
                            label: t("user.status.inactive"),
                            value: "inactive"
                        }, {
                            label: t("user.status.cancelled"),
                            value: "cancelled"
                        }, {
                            label: t("user.status.downgrade"),
                            value: "downgrade"
                        }]} />
                        <Input type="select" label={t("users.role")} value={filterRole ?? ""} onChange={value => onChangeFilterRole(value || null)} choices={[{
                            label: t("filters.all"),
                            value: ""
                        }, ...roles.map(role => ({
                            value: role,
                            label: t(`user.role.${role}`)
                        }))]} />
                    </Filter>
                } />
                <Pagination borderBottom {...props} />
                <div className="flex-1 overflow-x-auto">
                    {users && (
                        <Table
                            sortBy={sortBy}
                            onChangeSortBy={onChangeSortBy}
                            head={[{
                                name: "email",
                                label: t("users.email")
                            }, {
                                name: "id",
                                label: t("id")
                            }, {
                                name: "epoch_id",
                                label: t("users.epochId")
                            }, {
                                label: t("users.status")
                            }, {
                                name: "plan",
                                label: t("users.plan")
                            }, {
                                name: "role",
                                label: t("users.role")
                            }, {
                                name: "created_at",
                                label: t("users.createdAt")
                            }, {
                                fill: true
                            }]}
                            rows={users.map(({id, email, epoch_id, plan, next_subscription, role, created_at}) => ({
                                id,
                                data: [{
                                    className: "body-m-md",
                                    children: email
                                }, {
                                    children: id
                                }, {
                                    children: epoch_id
                                }, {
                                    children: (
                                        <div className="flex">
                                            {next_subscription !== null ? (next_subscription.plan === null ? (
                                                <StatusTag variant="warning">{t("user.status.cancelled")}</StatusTag>
                                            ) : (
                                                <StatusTag variant="warning">{t("user.status.downgrade")}</StatusTag>
                                            )) : plan !== null || role === "admin" || role === "permanentAccess" ? (
                                                <StatusTag variant="success">{t("user.status.active")}</StatusTag>
                                            ) : (
                                                <StatusTag variant="neutral">{t("user.status.inactive")}</StatusTag>
                                            )}
                                        </div>
                                    )
                                }, {
                                    children: plan ? `${plan.name}${next_subscription?.plan ? ` -> ${next_subscription.plan.name}` : ""}` : "-"
                                }, {
                                    children: t(`user.role.${role}`)
                                }, {
                                    children: dayjs(created_at).format("L")
                                }, {
                                    className: "text-right",
                                    children: (
                                        <div className="flex justify-end">
                                            <DotsDropdown items={[{
                                                onClick: handleEditUser(id),
                                                children: t("users.edit")
                                            }, {
                                                onClick: () => handleDeleteUser(id),
                                                children: t("users.delete"),
                                                variant: "danger"
                                            }]} />
                                        </div>
                                    )
                                }]
                            }))}
                        />
                    )}
                </div>
            </div>
            <Pagination {...props} />
            {editUserId && (
                <Popup>
                    <Form form={form} onSubmit={data => handleUpdateUser(editUserId, data)}>
                        <PopupContent className="w-xl" title={t("users.edit.title")}>
                            <div className="flex flex-col gap-6">
                                <div className="flex gap-3">
                                    <EmailInput className="flex-1" options={{required: t("input.email.required")}} placeholder={t("input.email.placeholder")} />
                                    <Input className="flex-1" type="select" name="role" label={t("users.role")} choices={roles.map(role => ({
                                        value: role,
                                        label: t(`user.role.${role}`)
                                    }))} />
                                </div>
                                <div className="flex gap-3">
                                    <Input className="flex-1" name="password" type="password" label={t("users.password")} placeholder={t("input.password.unchanged")} />
                                    <div className="flex-1" />
                                </div>
                            </div>
                        </PopupContent>
                        <PopupActions>
                            <Button variant="secondary" onClick={() => setEditUserId(null)}>{t("cancel")}</Button>
                            <Button variant="primary" submit>{t("save")}</Button>
                        </PopupActions>
                    </Form>
                </Popup>
            )}
        </>
    )
}
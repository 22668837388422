import { byId } from "@/js/common";
import { resourceDndSorting } from "@/js/dnd";
import { PlanUpdate, usePlans } from "@/js/resources";
import { AxiosError } from "axios";
import React from "react";
import { route } from "ziggy-js";
import PlansComponent from "../components/pages/Plans";

export default function Plans() {
    const [plans, {store, update}] = usePlans();

    const handleUpdate = async (id: number, data: Partial<PlanUpdate>, force: boolean) => {
        try {
            await update(id, data, "on-success", {
                params: { force }
            });
            if (data.delete || data.visibility === "unlisted") {
                const current = plans.find(byId(id));
                const next = plans.find(plan => plan.previous_id === id);
                if (next) {
                    update(next.id, {previous_id: current?.previous_id}, "local-only");
                }
            }
            return true;
        }
        catch (e) {
            if (e instanceof AxiosError && e.response?.status === 409) {
                return false;
            }
            else {
                throw e;
            }
        }
    }

    return (
        <PlansComponent
            plans={plans}
            onCopy={sid => navigator.clipboard.writeText(import.meta.env.VITE_APP_URL + route("subscriptions.unlock", {sid}, false))}
            onUpdate={handleUpdate}
            onCreate={async data => void await store(data)}
            onDelete={async id => void await handleUpdate(id, {delete: true}, false)}
            onDragDrop={resourceDndSorting(plans, update)}
            onRestore={async id => void await handleUpdate(id, {delete: false}, false)}
        />
    )
}
import { byId, now, sanitizeTranlations, toISOStringWithTimezone } from "@/js/common";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { Category, ImageSet, ImageSetUpdate, Model } from "@/js/resources";
import { status, Status } from "@/js/types";
import { setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getTitle } from "../../common";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Filter from "../Filter";
import Button from "../form/Button";
import Input from "../form/Input";
import SelectModels from "../form/SelectModels";
import Pagination from "../Pagination";
import SettingsPopup, { Submit } from "../popup/SettingsPopup";
import StatusTag from "../StatusTag";
import Table, { SortBy } from "../Table";
import TableHeader from "../TableHeader";

export type ImageSetsSortBy = SortBy<"id" | "title" | "category" | "publish_at">;

export default function ImageSets({
    imageSets,
    categories,
    models,
    sortBy,
    onChangeSortBy,
    onImageSetCreate,
    onImageSetUpdate,
    onImageSetDelete,
    onSearch,
    filterStatus,
    onChangeFilterStatus,
    filterCategory,
    onChangeFilterCategory,
    filterModels,
    onChangeFilterModels,
    ...props
}: {
    imageSets?: Pick<ImageSet, "id" | "translations" | "images_count" | "category" | "category_id" | "publish_at" | "featured_preview" | "unpublished_at" | "models">[],
    categories?: Pick<Category, "id" | "title">[],
    models?: Pick<Model, "id" | "first_name" | "last_name" | "description" | "avatar">[],
    sortBy: ImageSetsSortBy,
    onChangeSortBy: (sortBy: ImageSetsSortBy) => void,
    onImageSetUpdate: (id: number, data: Partial<ImageSetUpdate>) => void | Promise<void>,
    onImageSetCreate: (data: Omit<ImageSetUpdate, "view" | "favorite" | "watch_later" | "rating">) => void | Promise<void>,
    onImageSetDelete: (id: number) => void | Promise<void>,
    page: number,
    itemsPerPage: number,
    totalItems: number,
    onSearch: (search: string) => void,
    onChangePage: (page: number) => void,
    filterStatus: Exclude<Status, "error" | "processing"> | null,
    onChangeFilterStatus: (filterStatus: Exclude<Status, "error" | "processing"> | null) => void,
    filterCategory: number | null,
    onChangeFilterCategory: (filterCategory: number | null) => void,
    filterModels: number[],
    onChangeFilterModels: (filterModels: number[]) => void
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();
    const withPopup = usePopup();
    const [editImageSetId, setEditImageSetId] = useState<number | "create" | null>(null);
    const activeFilters = useMemo(() => [filterStatus, filterCategory].reduce<number>((prev, cur) => cur !== null ? prev + 1 : prev, 0) + (filterModels.length > 0 ? 1 : 0), [filterStatus, filterCategory, filterModels]);

    const form = useForm<Submit>();

    const handleEditImageSet = (id: number) => {
        const imageSet = requireNotNull(imageSets?.find(byId(id)), "unable to find image set");
        setEditImageSetId(id);
        console.log(imageSet);
        setFormValues(form, {
            category_id: imageSet.category_id,
            models: imageSet.models?.map(({id}) => id),
            translations: imageSet.translations,
            publish_at: toISOStringWithTimezone(imageSet.publish_at, true) as any
        });
    }

    const handleToggleUnpublishImageSet = (id: number) => {
        const imageSet = requireNotNull(imageSets?.find(byId(id)), "unable to find image set");
        const title = getTitle(imageSet.translations);
        withPopup(imageSet.unpublished_at !== null ? {
            title: t("imageSets.publish"),
            text: title ? t("imageSets.publish.text.title", {title}) : t("imageSets.publish.text.id", {id}),
            confirm: t("imageSets.publish"),
            type: "confirm",
            onConfirm: () => onImageSetUpdate(id, {unpublished: false})
        } : {
            title: t("imageSets.unpublish"),
            text: title ? t("imageSets.unpublish.text.title", {title}) : t("imageSets.unpublish.text.id", {id}),
            confirm: t("imageSets.unpublish"),
            type: "confirm",
            onConfirm: () => onImageSetUpdate(id, {unpublished: true})
        });
    }

    const handleDeleteImageSet = (id: number) => {
        const imageSet = requireNotNull(imageSets?.find(byId(id)), "unable to find image set");
        const title = getTitle(imageSet.translations);
        withPopup({
            title: t("imageSets.delete"),
            text: title ? t("imageSets.delete.text.title", {title}) : t("iamges.delete.text.id", {id}),
            confirm: t("imageSets.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: () => onImageSetDelete(id)
        });
    }

    const handleSubmit: SubmitHandler<Submit> = async data => {
        assertNotNull(editImageSetId);
        const sanitized = {
            ...data,
            translations: sanitizeTranlations(data.translations)
        }
        await (editImageSetId === "create" ? onImageSetCreate({
            ...sanitized,
            unpublished: false
        }) : onImageSetUpdate(editImageSetId, sanitized));
        setEditImageSetId(null);
    }

    return <>
        <Breadcrumbs breadcrumbs={[{children: t("imageSets")}]} />
        <div className="flex flex-col flex-1 overflow-y-auto">
            <TableHeader onSearch={onSearch} filter={
                <Filter active={activeFilters}>
                    <Input type="select" label={t("status")} value={filterStatus ?? ""} onChange={value => onChangeFilterStatus(value || null)} choices={[{
                        label: t("filters.all"),
                        value: ""
                    }, ...status.filter(status => status !== "error" && status !== "processing").map(status => ({
                        label: t(`status.${status}`),
                        value: status
                    }))]} />
                    <Input type="select" label={t("category")} value={filterCategory?.toString() ?? ""} onChange={value => onChangeFilterCategory(value ? Number(value) : null)} choices={[{
                        label: t("filters.all"),
                        value: ""
                    }, ...categories?.map(({id, title}) => ({
                        label: title,
                        value: id.toString()
                    })) ?? []]} />
                    <SelectModels label={t("models")} placeholder={t("filters.all")} models={models ?? []} value={filterModels} onChange={onChangeFilterModels} />
                </Filter>
            }>
                <Button variant="primary" onClick={() => setEditImageSetId("create")}>{t("imageSets.add")}</Button>
            </TableHeader>
            <Pagination borderBottom {...props} />
            <div className="flex-1 overflow-x-auto">
                {imageSets && (
                    <Table
                        sortBy={sortBy}
                        onChangeSortBy={onChangeSortBy}
                        head={[{
                            label: t("imageSets.preview")
                        }, {
                            name: "title",
                            label: t("imageSets.title")
                        }, {
                            name: "id",
                            label: t("id")
                        }, {
                            name: "category",
                            label: t("imageSets.category")
                        }, {
                            label: t("imageSets.status")
                        }, {
                            label: t("imageSets.models")
                        }, {
                            name: "publish_at",
                            label: t("imageSets.publishAt")
                        }, {
                            fill: true
                        }]}
                        rows={imageSets.map(({id, translations, category, featured_preview, images_count, unpublished_at, publish_at, models}) => ({
                            id,
                            data: [{
                                children: featured_preview ? (
                                    <img className="h-12 w-auto max-w-none aspect-image skeleton object-cover" {...featured_preview.thumbnail} id={undefined} />
                                ) : (
                                    <div className="h-12 aspect-image bg-neutral-600" />
                                )
                            }, {
                                className: "body-m-md",
                                children: getTitle(translations) || "-"
                            }, {
                                children: id
                            }, {
                                children: category?.title
                            }, {
                                children: (
                                    <div className="flex">
                                        {images_count < 3 ? (
                                            <StatusTag variant="neutral">{t("status.incomplete")}</StatusTag>
                                        ) : unpublished_at !== null ? (
                                            <StatusTag variant="warning">{t("status.unpublished")}</StatusTag>
                                        ) : publish_at! > now ? (
                                            <StatusTag variant="neutral">{t("status.planned")}</StatusTag>
                                        ) : (
                                            <StatusTag variant="success">{t("status.published")}</StatusTag>
                                        )}
                                    </div>
                                )
                            }, {
                                children: models?.map(({first_name}) => first_name).join(", ")
                            }, {
                                children: dayjs(publish_at).format("L")
                            }, {
                                children: (
                                    <div className="flex justify-end">
                                        <DotsDropdown items={[{
                                            onClick: () => handleEditImageSet(id),
                                            children: t("imageSets.settings")
                                        }, {
                                            to: `/images/${id}/files`,
                                            children: t("imageSets.editImages")
                                        }, {
                                            onClick: () => handleToggleUnpublishImageSet(id),
                                            children: unpublished_at !== null ? t("imageSets.publish") : t("imageSets.unpublish")
                                        }, {
                                            onClick: () => handleDeleteImageSet(id),
                                            variant: "danger",
                                            children: t("imageSets.delete")
                                        }]} />
                                    </div>
                                )
                            }]
                        }))}
                    />
                )}
            </div>
        </div>
        <Pagination {...props} />
        {categories && models && editImageSetId !== null && (
            <SettingsPopup
                title={editImageSetId === "create" ? t("imageSets.create") : t("imageSets.settings")}
                form={form}
                onSubmit={handleSubmit}
                onCancel={() => setEditImageSetId(null)}
                categories={categories}
                models={models}
            />
        )} 
    </>
}
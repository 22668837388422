import { formatDuration, formatFilesize } from "@/js/common";
import VideoPlayer from "@/js/main/components/VideoPlayer";
import { VideoResolution } from "@/js/resources";
import { Resolution, resolutions as videoResolutions } from "@/js/types";
import PlayIcon from "@/svg/play-solid.svg?react";
import compare from "@enymo/comparison";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../Breadcrumbs";
import FileInput, { FileInputRef } from "../FileInput";
import StatusTag from "../StatusTag";
import Table from "../Table";
import TableHeader from "../TableHeader";
import TableProgress from "../TableProgress";
import Button from "../form/Button";
import Popup from "../popup/Popup";
import PopupActions from "../popup/PopupActions";

export default function EditVideo({title, resolutions, upload, onUpload}: {
    title?: string,
    resolutions?: VideoResolution[]
    upload?: {
        progress: number,
        size: number
    }
    onUpload: (file: File) => Promise<void> | void
}) {
    const {t} = useTranslation();
    const ref = useRef<FileInputRef>(null);
    const [showPreview, setShowPreview] = useState<string | null>(null);

    const canUpload = useMemo(() => resolutions && !resolutions.some(resolution => resolution.status !== "done"), [resolutions]);

    const sortedResolutions = useMemo(
        () => resolutions?.toSorted(
            (a, b) => compare(a.type, b.type, ["source", "derived"]) || compare(b.resolution, a.resolution, videoResolutions as unknown as Resolution[])
        ),
        [resolutions]
    )

    return <>
        <Breadcrumbs breadcrumbs={[{
            to: "/videos",
            children: t("videos")
        }, {
            children: title
        }]} />
        <div className="flex-1 flex flex-col overflow-y-auto">
            <TableHeader title={t("videos.files")}>
                {canUpload && <Button variant="primary" onClick={() => ref.current?.open()} disabled={upload !== undefined}>{t("videos.files.upload")}</Button>}
            </TableHeader>
            <div className="flex-1 overflow-x-auto">
                <Table
                    head={[{
                        label: t("videos.files.video")
                    }, {
                        label: t("videos.files.type")
                    }, {
                        label: t("videos.files.resolution")
                    }, {
                        label: t("videos.files.duration")
                    }, {
                        label: t("videos.files.size")
                    }, {
                        fill: true
                    }]}
                    rows={upload ? [{
                        id: 1,
                        data: [{
                            children: (
                                <div className="h-20 aspect-video skeleton" />
                            )
                        }, {
                            children: (
                                <StatusTag variant="primary">{t("videos.files.source")}</StatusTag>
                            )
                        }, {
                            children: "-"
                        }, {
                            children: "-"
                        }, {
                            children: formatFilesize(upload.size)
                        }, {
                            children: (
                                <TableProgress loading>{t("videos.files.uploadProgress", {progress: Math.round(upload.progress * 100)})}</TableProgress>
                            )
                        }]
                    }] : sortedResolutions?.map(({id, resolution, thumbnail, duration, status, filesize, type, url}) => ({
                        id,
                        data: [{
                            children: (
                                <button className="relative" onClick={() => setShowPreview(url ?? null)}>
                                    <img className="h-20 aspect-video w-auto max-w-none object-cover skeleton" {...thumbnail} id={undefined} />
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <PlayIcon className="size-5 fill-white/80" />
                                    </div>
                                </button>
                            )
                        }, {
                            children: (
                                <div className="flex">
                                    {type === "source" ? (
                                        <StatusTag variant="primary">{t("videos.files.source")}</StatusTag>
                                    ) : (
                                        <StatusTag variant="neutral">{t("videos.files.derived")}</StatusTag>
                                    )}
                                </div>
                            )
                        }, {
                            children: t(`resolution.${resolution}`)
                        }, {
                            children: formatDuration(duration)
                        }, {
                            children: filesize !== null ? formatFilesize(filesize) : "-"
                        }, {
                            children: status !== "done" && (
                                <TableProgress loading={status === "processing"}>{t(`videos.files.status.${type}.${status}`)}</TableProgress>
                            )
                        }]
                    })) ?? []}
                />
            </div>
        </div>
        <FileInput ref={ref} accept="video/*" onSelected={([file]) => onUpload(file)} />
        {showPreview !== null && (
            <Popup onBackgroundClick={() => setShowPreview(null)}>
                <VideoPlayer src={showPreview} className="w-xl" />
                <PopupActions align="end">
                    <Button variant="secondary" onClick={() => setShowPreview(null)}>{t("close")}</Button>
                </PopupActions>
            </Popup>
        )}
    </>
}
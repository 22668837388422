import { StaticPage, StaticPageUpdate } from "@/js/resources";
import { Locale, locales } from "@/js/types";
import { EmitterSource } from "quill";
import { Delta } from "quill/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useQuill from "../../hooks/QuillHook";
import Breadcrumbs from "../Breadcrumbs";
import Tabs from "../Tabs";
import Button from "../form/Button";

export default function StaticPageComponent({slug, translations: initialTranslations, onUpdate}: {
    slug: string,
    translations: StaticPage["translations"],
    onUpdate: (data: StaticPageUpdate) => void | Promise<void>
}) {
    const {t} = useTranslation();
    const [editTranslation, setEditTranslation] = useState<Locale>("de");
    const [quill, ref] = useQuill<HTMLDivElement>({
        modules: {
            toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false]}], ["bold", "italic", "underline", "strike"]]
        },
        theme: "snow"
    });
    const [translations, setTranslations] = useState(initialTranslations);

    useEffect(() => {
        setTranslations(initialTranslations);
        console.log(initialTranslations?.[editTranslation]?.text);
        quill?.setContents(initialTranslations?.[editTranslation]?.text ?? [], "api");
    }, [initialTranslations, quill, setTranslations])    

    useEffect(() => {
        if (quill) {
            const handleChange = (delta: Delta, oldContents: Delta, source: EmitterSource) => {
                if (source === "user") {
                    setTranslations(translations => ({
                        ...translations,
                        [editTranslation]: {
                            ...translations?.[editTranslation],
                            text: quill.getContents().ops
                        }
                    }));
                }
            }
            quill.on("text-change", handleChange);
            return () => {
                quill.off("text-change", handleChange)
            }
        }
    }, [quill, editTranslation, setTranslations]);

    const handleChangeTranslation = (locale: Locale) => {
        setEditTranslation(locale);
        quill?.setContents(translations?.[locale]?.text ?? []);
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            to: "/pages",
            children: t("pages")
        }, {
            children: `/${slug}`
        }]} />
        <div className="flex-1 overflow-auto flex flex-col items-center py-16">
            <div className="max-w-[800px] w-full flex flex-col">
                <Tabs className="mb-10" value={editTranslation} onChange={handleChangeTranslation} items={locales.map(locale => ({
                    value: locale,
                    children: locale.toUpperCase()
                }))} />
                <h2 className="heading-l mb-5">{t("pages.content")}</h2>
                <div className="h-96 flex flex-col" ref={ref} />
                <Button variant="primary" className="mt-8 self-end" onClick={() => onUpdate({translations})}>{t("saveChanges")}</Button>
            </div>
        </div>
    </>
}
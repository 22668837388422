import { useEffect, useRef } from "react";

export default function useAbortSignal() {
    const ref = useRef<AbortController>();

    useEffect(() => {
        ref.current = new AbortController;
        return () => ref.current?.abort();
    }, [ref]);

    return ref.current?.signal;
}
import ChevronRight from "@/svg/chevron-right-regular.svg?react";
import { Clickable } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";

export interface Breadcrumb {
    onClick?: () => void,
    to?: string,
    children: React.ReactNode
}

export default function Breadcrumbs({className, breadcrumbs}: {
    className?: string,
    breadcrumbs: Breadcrumb[]
}) {
    return (
        <div className={classNames("flex gap-2 items-center px-8 border-b border-neutral-100 h-14", className)}>
            {breadcrumbs.map(({onClick, to, children}, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <ChevronRight className="w-1.5 fill-neutral-500" />}
                    <Clickable
                        className={onClick || to ? "body-l" : "body-l-md"}
                        to={to}
                        onClick={onClick}
                    >{children}</Clickable>
                </React.Fragment>
            ))}
        </div>
    )
}
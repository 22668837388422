import { useDayjs } from "@/js/providers/DayjsProvider";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router";
import { route } from "ziggy-js";
import AnalyticsComponent, { AnalyticsValues } from "../components/pages/Analytics";

export default function Analytics() {
    const dayjs = useDayjs();
    const [searchParams, setSearchParams] = useSearchParams();

    const from = searchParams.get("from") ?? dayjs().startOf("month").format("YYYY-MM-DD");
    const to = searchParams.get("to") ?? dayjs().endOf("month").format("YYYY-MM-DD");

    const [analytics, setAnalytics] = useState<{
        current: AnalyticsValues,
        previous: AnalyticsValues
    }>()

    useEffect(() => {
        const abortController = new AbortController;
        axios.get(route("analytics.index", {from, to}), {signal: abortController.signal})
            .then(response => setAnalytics(response.data))
            .catch(e => {
                if (!(e instanceof AxiosError)) {
                    throw e;
                }
            });
        return () => abortController.abort();
    }, [from, to]);

    const handleUpdateSearchParam = (param: string) => (value: string) => {
        searchParams.set(param, value);
        setSearchParams(searchParams);
    }

    return (
        <AnalyticsComponent
            from={from}
            to={to}
            onChangeFrom={handleUpdateSearchParam("from")}
            onChangeTo={handleUpdateSearchParam("to")}
            {...analytics}
        />
    )
}
import { returnFalse } from "@/js/common";
import { ModelUpdate, useModels } from "@/js/resources";
import React, { useMemo } from "react";
import ModelsComponent, { ModelsSortBy } from "../components/pages/Models";
import usePaginator from "../hooks/PaginatorHook";

export default function Models() {
    const {page, changePage, q, search, sortBy, changeSortBy} = usePaginator<ModelsSortBy["name"]>();
    const [models, {store, update, destroy, refresh, meta}] = useModels({
        onCreated: returnFalse,
        params: useMemo(() => ({
            with: ["translations"],
            per_page: 50,
            q,
            page,
            sort_by: sortBy ?? {name: "id", direction: "desc"}
        }), [q, page, sortBy])
    });

    const handleStore = async (data: ModelUpdate) => {
        await store(data);
        await refresh();
    }

    if (!meta) {
        return null;
    }

    return (
        <ModelsComponent
            models={models}
            itemsPerPage={meta.per_page}
            onChangePage={changePage}
            sortBy={sortBy ?? {name: "id", direction: "desc"}}
            onChangeSortBy={changeSortBy}
            onCreate={handleStore}
            onUpdate={update}
            onDelete={destroy}
            onSearch={search}
            page={page}
            totalItems={meta.total}
        />
    )
}
import React from "react";
import CommonProvider from "../providers/CommonProvider";
import App from "./App";
import PopupProvider from "./providers/PopupProvider";

export default function Bootstrapper() {
    return (
        <React.StrictMode>
            <CommonProvider>
                <PopupProvider>
                    <App />
                </PopupProvider>
            </CommonProvider>
        </React.StrictMode>
    )
}
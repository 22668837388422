import { useDayjs } from "@/js/providers/DayjsProvider";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Card from "../analytics/Card";
import PeriodInput from "../analytics/PeriodInput";
import PeriodSelect from "../analytics/PeriodSelect";
import Breadcrumbs from "../Breadcrumbs";

export interface AnalyticsValues {
    users: number,
    payingUsers: number,
    activeUsers: number,
    signUps: number,
    newSubscriptions: number,
    reactivatedSubscriptions: number,
    cancellations: number,
    cancellationRate: number
}

const periods = ["thisYear", "lastMonth", "thisMonth", "lastWeek", "thisWeek", "yesterday", "today"] as const;
type Period = typeof periods[number];

export default function Analytics({
    current,
    previous,
    from,
    to,
    onChangeFrom,
    onChangeTo
}: {
    current?: AnalyticsValues,
    previous?: AnalyticsValues,
    from: string,
    to: string,
    onChangeFrom: (from: string) => void,
    onChangeTo: (to: string) => void
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();

    const getPeriodDates = useCallback((period: Period) => {
        switch (period) {
            case "lastMonth":
                return [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month")
                ];
            case "lastWeek":
                return [
                    dayjs().subtract(1, "week").startOf("week"),
                    dayjs().subtract(1, "week").endOf("week")
                ];
            case "thisMonth":
                return [
                    dayjs().startOf("month"),
                    dayjs().endOf("month")
                ];
            case "thisWeek":
                return [
                    dayjs().startOf("week"),
                    dayjs().endOf("week")
                ];
            case "thisYear":
                return [
                    dayjs().startOf("year"),
                    dayjs().endOf("year")
                ];
            case "today":
                return [
                    dayjs(),
                    dayjs()
                ];
            case "yesterday":
                return [
                    dayjs().subtract(1, "day"),
                    dayjs().subtract(1, "day")
                ];
        }
    }, [dayjs]);

    const period = useMemo<Period | null>(() => {
        return periods.find(period => {
            const [fromDate, toDate] = getPeriodDates(period);
            return fromDate.isSame(dayjs(from), "day") && toDate.isSame(dayjs(to), "day");
        }) ?? null;
    }, [from, to, getPeriodDates]);

    const cardPeriod = useMemo(() => {
        if (period === "thisMonth" || period === "lastMonth") {
            return "month";
        }
        else if (period === "thisYear") {
            return "year";
        }
        else if (period === "lastWeek" || period === "thisWeek") {
            return "week";
        }
        else if (period === "yesterday" || period === "today") {
            return "day";
        }
        else {
            return "other";
        }
    }, [period]);

    const handleSetPeriod = (period: Period) => {
        const [from, to] = getPeriodDates(period);
        onChangeFrom(from.format("YYYY-MM-DD"));
        onChangeTo(to.format("YYYY-MM-DD"));
    }

    return <>
        <Breadcrumbs breadcrumbs={[{children: t("dashboard")}]} />
        <div className="flex flex-col items-center py-16">
            <div className="flex flex-col gap-10 max-w-7xl w-full">
                <div className="flex justify-between items-center">
                    <PeriodSelect value={period} onChange={handleSetPeriod} choices={periods.map(period => ({
                        label: t(`analytics.period.${period}`),
                        value: period
                    }))} />
                    <PeriodInput from={from} to={to} onChangeFrom={onChangeFrom} onChangeTo={onChangeTo} />
                </div>
                <div className="flex flex-col gap-5">
                    <h2 className="heading-l">{t("analytics.users")}</h2>
                    <div className="grid grid-cols-4 gap-5">
                        <Card
                            period={cardPeriod}
                            title={t("analytics.totalUsers")}
                            value={current?.users}
                            previous={previous?.users}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.payingUsers")}
                            value={current?.payingUsers}
                            previous={previous?.payingUsers}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.activeUsers")}
                            value={current?.activeUsers}
                            previous={previous?.activeUsers}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.signUps")}
                            value={current?.signUps}
                            previous={previous?.signUps}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-5">
                    <h2 className="heading-l">{t("analytics.subscriptions")}</h2>
                    <div className="grid grid-cols-4 gap-5">
                        <Card
                            period={cardPeriod}
                            title={t("analytics.newSubscriptions")}
                            value={current?.newSubscriptions}
                            previous={previous?.newSubscriptions}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.reactivatedSubscriptions")}
                            value={current?.reactivatedSubscriptions}
                            previous={previous?.reactivatedSubscriptions}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.cancellations")}
                            value={current?.cancellations}
                            previous={previous?.cancellationRate}
                        />
                        <Card
                            period={cardPeriod}
                            title={t("analytics.cancellationRate")}
                            value={current?.cancellationRate}
                            previous={previous?.cancellationRate}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}
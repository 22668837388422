import { Category, Model, Translations } from "@/js/resources";
import { Locale, locales } from "@/js/types";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import classNames from "classnames";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Tabs from "../Tabs";
import Button from "../form/Button";
import Input from "../form/Input";
import KeywordInput from "../form/KeywordInput";
import SelectModels from "../form/SelectModels";
import Popup from "./Popup";
import PopupActions from "./PopupActions";
import PopupContent from "./PopupContent";

export interface Submit {
    publish_at: Date,
    category_id: number,
    translations: Translations,
    models: number[]
}

export default function SettingsPopup({title, categories, models, form, onSubmit, onCancel}: {
    title: React.ReactNode,
    categories: Pick<Category, "id" | "title">[],
    models: Pick<Model, "id" | "first_name" | "last_name" | "description" | "avatar">[],
    form: UseFormReturn<Submit>,
    onSubmit: SubmitHandler<Submit>,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [editTranslation, setEditTranslation] = useState<Locale>("de");

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onSubmit(data);
        handleClosePopup();
    }

    const handleClosePopup = () => {
        onCancel();
        form.reset();
        setEditTranslation("de");
    }

    return (
        <Popup>
            <Form form={form} onSubmit={handleSubmit}>
                <PopupContent className="w-xl" title={title}>
                    <div className="flex flex-col gap-6">
                        <div className="flex gap-3">
                            <Input className="flex-1" name="publish_at" type="date" label={t("settings.publishAt")} options={{
                                valueAsDate: true,
                                required: t("settings.publishAt.required")
                            }} />
                            <SelectModels className="flex-1" name="models" label={t("settings.models")} placeholder={t("select.placeholder")} options={{
                                required: t("settings.models.required")
                            }} models={models} />
                        </div>
                        <div className="flex gap-3">
                            <Input className="flex-1" name="category_id" type="select" label={t("settings.category")} placeholder={t("select.placeholder")} options={{
                                required: t("settings.category.required"),
                                valueAsNumber: true
                            }} choices={categories.map(({id, title}) => ({
                                value: id.toString(),
                                label: title
                            }))} />
                            <div className="flex-1" />
                        </div>
                    </div>
                    <Tabs className="mt-14 mb-7" items={locales.map(locale => ({
                        value: locale,
                        children: locale.toUpperCase()
                    }))} value={editTranslation} onChange={setEditTranslation} />
                    {locales.map(locale => (
                        <div key={locale} className={classNames("flex flex-col gap-6", {"hidden": locale !== editTranslation})}>
                            <Input name={`translations.${locale}.title`} label={t("settings.title")} options={{
                                required: locale === "de" && t("translations.required")
                            }} />
                            <Input type="textarea" rows={6} name={`translations.${locale}.description`} label={t("settings.description")} options={{
                                required: locale === "de" && t("translations.required")
                            }} />
                            <KeywordInput name={`translations.${locale}.keywords`} label={t("settings.keywords")} />
                        </div>
                    ))}
                </PopupContent>
                <PopupActions>
                    <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                    <Button variant="primary" submit>{t("save")}</Button>
                </PopupActions>
            </Form>
        </Popup>
    )
}
import CheckIcon from "@/svg/check-regular.svg?react";
import classNames from "classnames";
import React from "react";

export default function Checkbox({className, checked, highlight}: {
    className?: string,
    checked?: boolean,
    highlight?: "error" | "focus"
}) {
    return (
        <div className={classNames("rounded border group size-4.5 flex justify-center items-center", checked ? "bg-primary-500" : "bg-bg-100 peer-checked:bg-primary-500", {
            [checked ? "border-primary-500" : "border-neutral-300 peer-checked:border-primary-500 peer-focus:border-primary-400"]: highlight === undefined,
            "border-primary-400": highlight === "focus",
            "border-error-500": highlight === "error"
        }, className)}>
            <CheckIcon className={classNames("w-3 fill-white peer-checked:group-[]:block", {
                "hidden": !checked
            })} />
        </div>
    )
}
import classNames from "classnames";
import React from "react";
import Error from "../Error";

export default function InputFrame({
    id,
    className,
    label,
    error,
    children
}: {
    id?: string,
    className?: string,
    label?: string,
    error?: string,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("flex flex-col", className)}>
            {label && <label htmlFor={id} className="body-m-md mb-2.5">{label}</label>}
            {children}
            <Error className="mt-1.5">{error}</Error>
        </div>
    )
}
import { byId, mapObject } from "@/js/common";
import { sortLinkedList } from "@/js/linkedList";
import { Category, CategoryUpdate } from "@/js/resources";
import { Locale, locales } from "@/js/types";
import { Form, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import { AxiosError } from "axios";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getTitle } from "../../common";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import StatusTag from "../StatusTag";
import Table, { DndHandler } from "../Table";
import TableHeader from "../TableHeader";
import Tabs from "../Tabs";
import Button from "../form/Button";
import ImageInput from "../form/ImageInput";
import Input from "../form/Input";
import Popup from "../popup/Popup";
import PopupActions from "../popup/PopupActions";
import PopupContent from "../popup/PopupContent";

export default function Categories({
    categories,
    onCreate,
    onUpdate,
    onDelete,
    onDragDrop
}: {
    categories?: Omit<Category, "title" | "description">[],
    onCreate: (data: CategoryUpdate) => void | Promise<void>,
    onUpdate: (id: number, data: Partial<CategoryUpdate>) => void | Promise<void>,
    onDelete: (id: number) => void | Promise<void>,
    onDragDrop: DndHandler<number>
}) {
    const {t} = useTranslation();
    const popup = usePopup();
    const [editCategoryId, setEditCategoryId] = useState<number | "create" | null>(null);
    const editCategory = useMemo(() => categories?.find(byId(editCategoryId)), [categories, editCategoryId]);
    const [editTranslation, setEditTranslation] = useState<Locale>("de");
    const sortedCategories = useMemo(() => categories && sortLinkedList(categories), [categories]);
    const form = useForm<CategoryUpdate>();

    const handleEditCategory = (id: number) => {
        const category = requireNotNull(categories?.find(byId(id)), "unable to find category");
        setFormValues(form, {
            translations: category.translations
        });
        setEditCategoryId(id);
    }

    const handleClosePopup = () => {
        form.reset();
        setEditCategoryId(null);
        setEditTranslation("de");
    }

    const handleSubmit: SubmitHandler<CategoryUpdate> = async data => {
        assertNotNull(editCategoryId);
        const sanitized = {
            ...data,
            translations: mapObject(data.translations, translation => translation?.title && translation.description ? translation : null)
        }
        await (editCategoryId === "create" ? onCreate(sanitized) : onUpdate(editCategoryId, sanitized));
        handleClosePopup();
    }

    const handleDeleteCategory = (id: number) => {
        const category = requireNotNull(categories?.find(byId(id)), "unable to find category");
        const title = getTitle(category.translations);
        popup({
            title: t("categories.delete"),
            text: title ? t("categories.delete.text.title", {title}) : t("categories.delete.text.id", {id}),
            confirm: t("categories.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: async () => {
                try {
                    await onDelete(id)
                }
                catch (e) {
                    if (e instanceof AxiosError && e.status === 409) {
                        return {
                            title: t("categories.delete.conflict"),
                            text: t("categories.delete.conflict.text"),
                            confirm: t("ok"),
                            type: "info",
                            variant: "danger"
                        }
                    }
                    throw e;
                }
            }
        });
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            children: t("categories")
        }]} />
        <div className="flex-1 overflow-auto">
            <TableHeader>
                <Button variant="primary" onClick={() => setEditCategoryId("create")}>{t("categories.add")}</Button>
            </TableHeader>
            {sortedCategories && (
                <Table
                    onDragDrop={onDragDrop}
                    head={[{
                        label: t("categories.logo")
                    }, {
                        label: t("categories.preview")
                    }, {
                        label: t("categories.title")
                    }, {
                        label: t("id"),
                        colSpan: 2
                    }, {
                        fill: true
                    }]}
                    rows={sortedCategories.map(({id, logo, preview, translations}, index) => ({
                        id,
                        data: [{
                            children: (
                                <div className="flex justify-center">
                                    <img className="h-12 w-auto max-w-none" {...logo} id={undefined} />
                                </div>
                            )
                        }, {
                            children: (
                                <img className="h-12 w-auto max-w-none aspect-card object-cover skeleton" {...preview} id={undefined} />
                            )
                        }, {
                            className: "body-m-md",
                            children: getTitle(translations) || "-"
                        }, {
                            children: id
                        }, {
                            children: index < 5 && (
                                <StatusTag variant="primary">{t("categories.home")}</StatusTag>
                            )
                        }, {
                            children: (
                                <div className="flex justify-end">
                                    <DotsDropdown items={[{
                                        onClick: () => handleEditCategory(id),
                                        children: t("categories.settings")
                                    }, {
                                        onClick: () => handleDeleteCategory(id),
                                        children: t("categories.delete"),
                                        variant: "danger"
                                    }]} />
                                </div>
                            )
                        }]
                    }))}
                />
            )}
        </div>
        {editCategoryId && (
            <Popup>
                <Form form={form} onSubmit={handleSubmit}>
                    <PopupContent className="w-xl" title={editCategoryId === "create" ? t("categories.create") : t("categories.settings")}>
                        <div className="flex flex-col gap-7">
                            <div className="flex gap-9">
                                <ImageInput className="flex-1" name="logo" label={t("categories.logo")} options={{
                                    required: editCategoryId === "create" && t("categories.logo.required")
                                }} src={editCategory?.logo?.src} />
                                <ImageInput className="flex-1" name="preview" label={t("categories.preview")} options={{
                                    required: editCategoryId === "create" && t("categories.preview.required")
                                }} src={editCategory?.preview?.src} />
                            </div>
                            <Tabs value={editTranslation} onChange={setEditTranslation} items={locales.map(locale => ({
                                value: locale,
                                children: locale.toUpperCase()
                            }))} />
                            {locales.map(locale => (
                                <div key={locale} className={classNames("flex flex-col gap-6", {"hidden": locale !== editTranslation})}>
                                    <Input name={`translations.${locale}.title`} label={t("categories.title")} options={{
                                        required: locale === "de" && t("translations.required")
                                    }} />
                                    <Input type="textarea" rows={6} name={`translations.${locale}.description`} label={t("categories.description")} options={{
                                        required: locale === "de" && t("translations.required")
                                    }} />
                                </div>
                            ))}
                            
                        </div>
                    </PopupContent>
                    <PopupActions>
                        <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                        <Button variant="primary" submit>{t("save")}</Button>
                    </PopupActions>
                </Form>
            </Popup>
        )}
    </>
}
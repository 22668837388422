import classNames from "classnames";
import React from "react";

export default function PopupActions({className, align = "space", children}: {
    className?: string,
    align?: "space" | "end",
    children: React.ReactNode
}) {
    return (
        <div className={classNames("px-7 h-16 gap-7 flex items-center bg-bg-300", align === "space" ? "justify-between" : "justify-end", className)}>
            {children}
        </div>
    )
}
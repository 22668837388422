import ChevronDown from "@/svg/chevron-down-regular.svg?react";
import MagnifyingGlassIcon from "@/svg/magnifying-glass-regular.svg?react";
import { GlissadeInput, GlissadeInputProps, useGlissadeInput } from "@enymo/glissade";
import classNames from "classnames";
import React, { useCallback, useId } from "react";
import InputFrame from "./InputFrame";

export interface InputProps<T extends string = string> extends Omit<GlissadeInputProps<T>, "id"> {
    label?: string,
    error?: string,
    onSearch?: (value: string) => void
}

export default function Input<T extends string = string>({
    className,
    label,
    error: errorProp,
    name,
    disabled,
    type,
    onSearch,
    ...props
}: InputProps<T>) {
    const id = useId();
    const {error, ...glissade} = useGlissadeInput({name, disabled, error: errorProp});

    const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>>(e => {
        if (e.key === "Enter") {
            onSearch?.(e.currentTarget.value);
        }
    }, [onSearch]);

    return (
        <InputFrame id={id} className={className} label={label} error={error}>
            <div className="relative">
                <GlissadeInput
                    id={id}
                    className={classNames(
                        "px-2.5 w-full rounded-md bg-bg-100 body-m placeholder:text-text-200 border",
                        error ? "border-error-500" : "border-neutral-300 hover:border-neutral-400 focus:border-primary-500",
                        type === "textarea" ? "py-2" : "h-8.5",
                        {
                            "pl-8": type === "search",
                            "pr-9": type === "select"
                        }
                    )}
                    name={name}
                    type={type}
                    onKeyDown={handleKeyDown}
                    {...glissade}
                    {...props}
                />
                {type === "search" ? (
                    <MagnifyingGlassIcon className="w-3.5 fill-neutral-400 pointer-events-none absolute top-1/2 -translate-y-1/2 left-2.5" />
                ) : type === "select" && (
                    <ChevronDown className="w-3 fill-neutral-500 pointer-events-none absolute top-1/2 -translate-y-1/2 right-3" />
                )}
            </div>
        </InputFrame>
    )
}
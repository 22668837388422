import { resourceDndSorting } from "@/js/dnd";
import { deleteResourceLinkedListItem } from "@/js/linkedList";
import { useImageSetImages, useImageSets } from "@/js/resources";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { getTitle } from "../common";
import ImagesComponent from "../components/pages/Images";

const params = {
    with: ["translations"]
}

export default function Images() {
    const id = Number(useParams().id);
    const nextId = useRef(1);
    const [imageSet] = useImageSets({id, params});
    const [images, {destroy, update, store}] = useImageSetImages({params: useMemo(() => ({image_set: id}), [id])});
    const [uploads, setUploads] = useState<{
        id: number,
        src: string,
        filename: string,
        progress: number
    }[]>([]);

    const handleUpload = (files: File[]) => {
        for (const file of files) {
            const id = nextId.current++;
            const src = URL.createObjectURL(file);
            setUploads(uploads => [...uploads, {
                id,
                filename: file.name,
                src,
                progress: 0
            }]);
            store({
                content: file
            }, "on-success", {
                onUploadProgress: ({progress}) => {
                    if (progress !== undefined) {
                        setUploads(uploads => uploads.map(upload => upload.id === id ? {
                            ...upload,
                            progress
                        } : upload))
                    }
                }
            }).then(() => {
                URL.revokeObjectURL(src)
                setUploads(uploads => uploads.filter(upload => upload.id !== id));
            });
        }
    }

    return (
        <ImagesComponent
            title={getTitle(imageSet?.translations) ?? undefined}
            images={images}
            onDelete={id => deleteResourceLinkedListItem(images, id, update, destroy)}
            onUpload={handleUpload}
            onDragDrop={resourceDndSorting(images, update)}
            uploads={uploads}
        />
    )
}
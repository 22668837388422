import logo from "@/media/admin-logo.png";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../form/Button";
import EmailInput from "../form/EmailInput";
import Input from "../form/Input";

export interface Submit {
    email: string,
    password: string
}

export default function Login({className, onLogin}: {
    className?: string,
    onLogin: (data: Submit) => void | Promise<void>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>();

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onLogin(data);
        form.reset();
    }

    return (
        <div className={classNames("flex flex-col gap-11 justify-center items-center bg-bg-300", className)}>
            <img className="h-15 w-auto max-w-none" src={logo} />
            <Form className="w-[460px] rounded-lg shadow-popup bg-bg-100 p-10 flex flex-col" form={form} onSubmit={handleSubmit}>
                <h1 className="heading-m mb-8 text-center">{t("login")}</h1>
                <EmailInput className="mb-7" options={{required: t("input.email.required")}} />
                <Input type="password" name="password" label={t("input.password")} className="mb-10" options={{
                    required: t("input.password.required")
                }} />
                <Button variant="primary" submit>{t("login")}</Button>
            </Form>
        </div>
    )
}
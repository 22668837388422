import { byId } from "@/js/common";
import { sortLinkedList } from "@/js/linkedList";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { BannerImage } from "@/js/resources";
import { Locale, locales } from "@/js/types";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import FileInput, { FileInputRef } from "../FileInput";
import Button from "../form/Button";
import Input from "../form/Input";
import Table, { DndHandler } from "../Table";
import TableProgress from "../TableProgress";

export default function Banners({
    locale,
    onChangeLocale,
    type,
    onChangeType,
    images,
    onUpload,
    onDelete,
    onDragDrop,
    uploads
}: {
    locale: Locale,
    onChangeLocale: (locale: Locale) => void,
    type: "top" | "bottom",
    onChangeType: (type: "top" | "bottom") => void,
    images?: BannerImage[],
    onUpload: (files: File[]) => void,
    onDelete: (id: number) => void | Promise<void>,
    onDragDrop: DndHandler<number>,
    uploads: {
        id: number,
        src: string,
        filename: string,
        progress: number
    }[]
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();
    const popup = usePopup();
    const ref = useRef<FileInputRef>(null);

    const sortedImages = useMemo(() => images && sortLinkedList(images.filter(({position}) => position === type)), [images, type]);

    const handleDelete = (id: number) => {
        const image = requireNotNull(images?.find(byId(id)), "unable to find image");
        popup({
            title: t("banners.delete"),
            text: t("banners.delete.text", {filename: image.filename}),
            confirm: t("banners.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: () => onDelete(id)
        });
    }

    return <>
        <Breadcrumbs breadcrumbs={[{
            children: t("banners")
        }]} />
        <div className="flex-1 overflow-y-auto">
            <div className="flex items-center justify-between h-16 px-8 border-neutral-200 border-b">
                <div className="flex gap-4">
                    <Input type="select" value={locale} onChange={onChangeLocale} choices={locales.map(locale => ({
                        label: t(`locale.${locale}`),
                        value: locale
                    }))} />
                    <Input type="select" value={type} onChange={onChangeType} choices={[{
                        label: t("banners.top"),
                        value: "top"
                    }, {
                        label: t("banners.bottom"),
                        value: "bottom"
                    }]} />
                </div>
                <Button variant="primary" onClick={() => ref.current?.open()}>{t("banners.upload")}</Button>
            </div>
            {sortedImages && (
                <PhotoProvider>
                    <Table
                        onDragDrop={onDragDrop}
                        head={[{
                            label: t("banners.image")
                        }, {
                            label: t("banners.filename")
                        }, {
                            label: t("banners.createdAt"),
                            colSpan: 2
                        }, {
                            fill: true
                        }]}
                        rows={[
                            ...sortedImages.map(({id, src, width, height, filename, created_at}) => ({
                                id,
                                data: [{
                                    children: (
                                        <div className="flex items-center">
                                            <PhotoView src={src} width={width} height={height}>
                                                <img className="h-12 w-auto max-w-none skeleton" src={src} width={width} height={height} />
                                            </PhotoView>
                                        </div>
                                    )
                                }, {
                                    className: "body-m-md",
                                    children: filename
                                }, {
                                    children: dayjs(created_at).format("L"),
                                    colSpan: 2
                                }, {
                                    children: (
                                        <div className="flex justify-end">
                                            <DotsDropdown items={[{
                                                onClick: () => handleDelete(id),
                                                children: t("banners.delete"),
                                                variant: "danger"
                                            }]} />
                                        </div>
                                    )
                                }]
                            })),
                            ...uploads.map(({id, src, filename, progress}) => ({
                                id: `upload-${id}` as any,
                                disableDnd: true,
                                data: [{
                                    children: (
                                        <div className="flex-items-center">
                                            <img className="h-12 auto max-w-none skeleton" src={src} />
                                        </div>
                                    )
                                }, {
                                    className: "body-m-md",
                                    children: filename
                                }, {
                                    children: "-"
                                }, {
                                    children: (
                                        <TableProgress loading>{t("banners.uploadProgress", {progress: Math.round(progress * 100)})}</TableProgress>
                                    ),
                                    colSpan: 2
                                }]
                            }))
                        ]}
                    />
                </PhotoProvider>
            )}
        </div>
        <FileInput ref={ref} accept="image/*" onSelected={onUpload} multiple />
    </>
}
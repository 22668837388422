import logo from "@/media/admin-logo.png";
import BoxDollarIcon from "@/svg/box-dollar-solid.svg?react";
import DashboardIcon from "@/svg/dashboard.svg?react";
import FolderGridIcon from "@/svg/folder-grid-solid.svg?react";
import ImagesIcon from "@/svg/images-solid.svg?react";
import RectangleVerticalHistoryIcon from "@/svg/rectangle-vertical-history-solid.svg?react";
import SitemapIcon from "@/svg/sitemap-solid.svg?react";
import UserHairLongIcon from "@/svg/user-hair-long-solid.svg?react";
import UsersIcon from "@/svg/users-regular.svg?react";
import VideoIcon from "@/svg/video-solid.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { User, UserUpdate } from "../../resources";
import Button from "./form/Button";
import EmailInput from "./form/EmailInput";
import Input from "./form/Input";
import Frame from "./Frame";
import Popup from "./popup/Popup";
import PopupActions from "./popup/PopupActions";
import PopupContent from "./popup/PopupContent";

export type Submit = Pick<UserUpdate, "name" | "email" | "password">;

export default function LayoutComponent({
    className,
    user,
    onUserUpdate,
    onLogout,
    children
}: {
    className?: string,
    children: React.ReactNode,
    user: Pick<User, "id" | "name" | "email">,
    onUserUpdate: SubmitHandler<Submit>,
    onLogout: () => void
}) {
    const {t} = useTranslation();
    const [showSettings, setShowSettings] = useState(false);

    const form = useForm<Submit>({values: {
        ...user,
        password: ""
    }});

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onUserUpdate(data);
        setShowSettings(false);
    }

    const handleShowPopup = () => {
        form.reset();
        setShowSettings(true);
    }

    return <>
        <Frame
            className={className}
            logo={
                <div className="flex px-4 py-1 gap-1 h-20">
                    <img src={logo} className="h-[50px] w-auto" width={1590} height={675} />
                    <span className="font-semibold text-sm text-text-400 mt-5">ADMIN</span>
                </div>
            }
            items={[{
                to: "/dashboard",
                icon: <DashboardIcon className="w-4" />,
                children: t("menu.dashboard")
            }, {
                to: "/users",
                icon: <UsersIcon className="w-4" />,
                children: t("menu.users")
            }, {
                to: "/videos",
                icon: <VideoIcon className="w-4" />,
                children: t("menu.videos")
            }, {
                to: "/images",
                icon: <ImagesIcon className="w-4" />,
                children: t("menu.images")
            }, {
                to: "/models",
                icon: <UserHairLongIcon className="w-3" />,
                children: t("menu.models")
            }, {
                to: "/categories",
                icon: <SitemapIcon className="w-4" />,
                children: t("menu.categories")
            }, {
                to: "/plans",
                icon: <BoxDollarIcon className="w-3.5" />,
                children: t("menu.plans")
            }, {
                to: "/pages",
                icon: <FolderGridIcon className="w-4" />,
                children: t("menu.pages")
            }, {
                to: "/banners",
                icon: <RectangleVerticalHistoryIcon className="w-4" />,
                children: t("menu.banners")
            }]}
            user={user.name ?? user.email}
            onLogout={onLogout}
            onUserSettingsClick={handleShowPopup}
        >
            {children}
        </Frame>
        {showSettings && (
            <Popup onBackgroundClick={() => setShowSettings(false)}>
                <Form form={form} onSubmit={handleSubmit}>
                    <PopupContent className="w-xl" title={t("profile.title")}>
                        <div className="flex flex-col gap-6">
                            <Input name="name" label={t("profile.name")} placeholder={t("profile.name.placeholder")} />
                            <EmailInput options={{
                                required: t("input.email.required")
                            }} />
                            <Input name="password" type="password" label={t("profile.password")} placeholder={t("input.password.unchanged")} options={{
                                minLength: {
                                    value: 8,
                                    message: t("input.password.minLength")
                                }
                            }} />
                        </div>
                    </PopupContent>
                    <PopupActions>
                        <Button variant="secondary" onClick={() => setShowSettings(false)}>{t("cancel")}</Button>
                        <Button variant="primary" submit>{t("save")}</Button>
                    </PopupActions>
                </Form>
            </Popup>
        )}
    </>
}
import { Model } from "@/js/resources";
import ChevronDown from "@/svg/chevron-down-regular.svg?react";
import { CheckboxList } from "@enymo/glissade";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import { requireNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckboxInput from "./CheckboxInput";
import InputFrame from "./InputFrame";

export default function SelectModels({
    className,
    name,
    value: externalValue,
    onChange: externalOnChange,
    options,
    placeholder,
    models,
    label: inputLabel,
    error: errorProp
}: {
    className?: string,
    name?: string,
    value?: number[],
    onChange?: (value: number[]) => void,
    options?: RegisterOptions,
    placeholder?: string,
    models: Pick<Model, "id" | "first_name" | "last_name" | "description" | "avatar">[],
    label?: string,
    error?: string
}) {
    const {t} = useTranslation();
    const {value, onChange, error} = useHybridInput({name, externalValue, externalOnChange, options, defaultValue: []});
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const ref = useOnClickOutside<HTMLDivElement>(() => {
        setOpen(false);
        setSearch("");
    }, [setOpen, setSearch]);

    const inputRef = useRef<HTMLInputElement>(null);

    const filteredModels = useMemo(
        () => search ? models.filter(({first_name, last_name, description}) => {
            const q = search.toLowerCase();
            return first_name.toLowerCase().includes(q) || last_name?.toLowerCase().includes(q) || description.toLowerCase().includes(q);
        }) : models,
        [models, search]
    );
    const label = useMemo(() => value.length === 0 ? (
        placeholder
    ) : value.length === 1 ? (
        requireNotNull(models.find(model => model.id === value[0]), "unable to find choice").first_name
    ) : (
        t("selectMultiple.selected", {amount: value.length})
    ), [value, placeholder, models]);

    useEffect(() => {
        if (open) {
            inputRef.current?.focus();
        }
    }, [inputRef, open]);

    return (
        <InputFrame label={inputLabel} className={className} error={errorProp ?? error?.message}>
            <div ref={ref} className="relative">
                <input
                    ref={inputRef}
                    type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t("search")}
                    className={classNames("w-full h-8.5 rounded-md bg-bg-100 pl-2.5 pr-8 body-m placeholder:text-text-200 border border-primary-500", {
                        "hidden": !open
                    })}
                />
                <button type="button" onClick={() => setOpen(true)} className={classNames("w-full h-8.5 rounded-md bg-bg-100 flex items-center pl-2.5 pr-8 body-m border border-neutral-300 hover:border-neutral-400", {
                    "hidden": open
                })}>
                    {label}
                </button>
                <ChevronDown className="w-3 fill-neutral-500 absolute top-1/2 -translate-y-1/2 right-3 pointer-events-none" />
                {open && (
                    <div className="absolute left-0 top-[calc(100%+2px)] rounded-md bg-bg-100 z-10 shadow-admin-dropdown flex flex-col px-3.5 py-3 gap-4 max-h-44 overflow-y-auto">
                        <CheckboxList value={value} onChange={onChange}>
                            {filteredModels.length > 0 ? filteredModels.map(({id, first_name, last_name, description, avatar}) => (
                                <CheckboxInput key={id} value={id}>
                                    <div className="flex gap-2.5 items-center">
                                        <img {...avatar} id={undefined} className="size-12 rounded-full object-cover skeleton" />
                                        <div className="flex flex-col">
                                            <span className="body-m">{first_name} {last_name}</span>
                                            <span className="body-s">{description}</span>
                                        </div>
                                    </div>
                                </CheckboxInput>
                            )): (
                                <span className="body-m text-text-200 italic">{t("selectMultiple.noResults")}</span>
                            )}
                        </CheckboxList>
                    </div>
                )}
            </div>
        </InputFrame>
    )
}
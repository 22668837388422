import { byId } from "@/js/common";
import { sortLinkedList } from "@/js/linkedList";
import { useDayjs } from "@/js/providers/DayjsProvider";
import { ImageSetImage } from "@/js/resources";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { usePopup } from "../../providers/PopupProvider";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import FileInput, { FileInputRef } from "../FileInput";
import Button from "../form/Button";
import StatusTag from "../StatusTag";
import Table, { DndHandler } from "../Table";
import TableHeader from "../TableHeader";
import TableProgress from "../TableProgress";

export default function Images({title, images, uploads, onUpload, onDragDrop, onDelete}: {
    title?: string
    images?: ImageSetImage[],
    uploads: {
        id: number,
        src: string,
        filename: string,
        progress: number
    }[],
    onUpload: (files: File[]) => void,
    onDragDrop: DndHandler<number>,
    onDelete: (id: number) => void | Promise<void>
}) {
    const {t, i18n} = useTranslation();
    const dayjs = useDayjs();
    const popup = usePopup();
    const ref = useRef<FileInputRef>(null);

    const sortedImages = useMemo(() => sortLinkedList(images ?? []), [images]);

    const handleDelete = (id: number) => {
        const image = requireNotNull(images?.find(byId(id)), "unable to find image");
        popup({
            title: t("imageSets.files.delete"),
            text: t("imageSets.files.delete.text", {filename: image.filename}),
            confirm: t("imageSets.files.delete"),
            type: "confirm",
            variant: "danger",
            onConfirm: () => onDelete(id)
        });
    }
    
    return <>
        <Breadcrumbs breadcrumbs={[{
            to: "/images",
            children: t("imageSets")
        }, {
            children: title
        }]} />
        <div className="flex flex-col flex-1 overflow-y-auto">
            <TableHeader title={t("imageSets.files")}>
                <Button variant="primary" onClick={() => ref.current?.open()}>{t("imageSets.files.upload")}</Button>
            </TableHeader>
            <div className="flex-1 overflow-x-auto">
                <PhotoProvider>
                    <Table
                        onDragDrop={onDragDrop}
                        head={[{
                            label: t("imageSets.files.image")
                        }, {
                            label: t("imageSets.files.filename")
                        }, {
                            label: t("imageSets.files.resolution")
                        }, {
                            label: t("imageSets.files.createdAt"),
                            colSpan: 3
                        }, {
                            fill: true
                        }]}
                        rows={[
                            ...sortedImages.map(({id, thumbnail, content, filename, created_at}, index) => {
                                assertNotNull(content, "content error");
                                return {
                                    id,
                                    data: [{
                                        children: (
                                            <PhotoView {...content}>
                                                <img className="h-20 aspect-image max-w-none w-auto object-cover skeleton" {...thumbnail} />
                                            </PhotoView>
                                        )
                                    }, {
                                        children: filename
                                    }, {
                                        children: t("resolution.px", {
                                            width: content.width.toLocaleString(i18n.language),
                                            height: content.height.toLocaleString(i18n.language)
                                        })
                                    }, {
                                        children: dayjs(created_at).format("L")
                                    }, {
                                        children: (
                                            <div className="flex">
                                                {index === 0 ? (
                                                    <StatusTag variant="primary">{t("imageSets.files.primary")}</StatusTag>
                                                ) : index < 5 && (
                                                    <StatusTag variant="primary">{t("imageSets.files.secondary")}</StatusTag>
                                                )}
                                            </div>
                                        ),
                                        colSpan: 2
                                    }, {
                                        children: (
                                            <div className="flex justify-end">
                                                <DotsDropdown items={[{
                                                    onClick: () => handleDelete(id),
                                                    children: t("imageSets.files.delete"),
                                                    variant: "danger"
                                                }]} />
                                            </div>
                                        )
                                    }]
                                }
                            }),
                            ...uploads.map(({id, src, filename, progress}) => ({
                                id: `upload-${id}` as any,
                                disableDnd: true,
                                data: [{
                                    children: (
                                        <img className="h-20 aspect-image max-w-none w-auto object-cover skeleton" src={src} />
                                    )
                                }, {
                                    children: filename
                                }, {
                                    children: "-"
                                }, {
                                    children: "-",
                                    colSpan: 2
                                }, {
                                    children: (
                                        <TableProgress loading>{t("imageSets.files.uploadProgress", {progress: Math.round(progress * 100)})}</TableProgress>
                                    ),
                                    colSpan: 2
                                }]
                            }))
                        ]}
                    />
                </PhotoProvider>
            </div>
        </div>
        <FileInput ref={ref} accept="image/*" onSelected={onUpload} multiple />
    </>
}
import useAtLocation from "@/js/main/hooks/AtLocationHook";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router";

export function Tab({to, children}: {
    to: string,
    children: React.ReactNode
}) {
    const active = useAtLocation(to);

    return (
        <Link className={classNames("relative pb-1.5 px-2.5 border-primary-400", {
                "text-text-500": active
            }
        )} to={to}>
            {children}
            {active && (
                <div className="absolute h-[3px] rounded-t-[1px] bg-primary-400 -bottom-px inset-x-0" />
            )}
        </Link>
    )
}

export default function Tabs<T extends string>({className, value, onChange, items, noBorder}: {
    className?: string,
    value: T,
    onChange: (value: T) => void,
    items: {
        value: T,
        children: React.ReactNode
    }[],
    noBorder?: boolean
}) {
    return (
        <div className={classNames("flex gap-1.5 body-l-md", {
            "border-neutral-200 border-b": !noBorder
        }, className)}>
            {items.map(({value: currentValue, children}, index) => (
                <button key={index} type="button" onClick={() => onChange(currentValue)} className={classNames(
                    "relative pb-1.5 px-2.5 border-primary-400",
                    {
                        "text-text-500": currentValue !== value
                    }
                )}>
                    {children}
                    {currentValue === value && (
                        <div className="absolute h-[3px] rounded-t-[1px] bg-primary-400 -bottom-px inset-x-0" />
                    )}
                </button>
            ))}
        </div>
    )
}
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router";
import { SortBy } from "../components/Table";

export default function usePaginator<T extends string>() {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number(searchParams.get("page")) || 1;
    const q = searchParams.get("q") || undefined;
    const sortByName = searchParams.get("sort_by[name]");
    const sortByDirection = searchParams.get("sort_by[direction]");
    const sortBy: SortBy<T> | null = useMemo(() => sortByName !== null && sortByDirection !== null ? {
        name: sortByName as T,
        direction: sortByDirection as "asc" | "desc"
    } : null, [sortByName, sortByDirection]);

    const search = useCallback((search: string) => {
        setSearchParams({
            q: search,
            page: "1"
        });
    }, [setSearchParams]);

    const changeSortBy = useCallback((sortBy: SortBy<T>) => {
        searchParams.set("page", "1");
        searchParams.set("sort_by[name]", sortBy.name);
        searchParams.set("sort_by[direction]", sortBy.direction);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams])
    
    const changePage = useCallback((page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    return {page, q, search, changePage, sortBy, changeSortBy};
}
import { resourceDndSorting } from "@/js/dnd";
import { deleteResourceLinkedListItem } from "@/js/linkedList";
import { useCategories } from "@/js/resources";
import React from "react";
import CategoriesComponent from "../components/pages/Categories";

const params = {
    with: ["translations"]
}

export default function Categories() {
    const [categories, {store, update, destroy}] = useCategories({params});

    return (
        <CategoriesComponent
            categories={categories}
            onCreate={async data => void await store(data)}
            onUpdate={update}
            onDelete={id => deleteResourceLinkedListItem(categories, id, update, destroy)}
            onDragDrop={resourceDndSorting(categories, update)}
        />
    )
}
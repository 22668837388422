import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";
import Gate from "./Gate";
import Layout from "./Layout";
import Analytics from "./pages/Analytics";
import Banners from "./pages/Banners";
import Categories from "./pages/Categories";
import EditVideo from "./pages/EditVideo";
import Images from "./pages/Images";
import ImageSets from "./pages/ImageSets";
import Login from "./pages/Login";
import Models from "./pages/Models";
import Plans from "./pages/Plans";
import StaticPagePage from "./pages/StaticPagePage";
import StaticPages from "./pages/StaticPages";
import Users from "./pages/Users";
import VideoPreviews from "./pages/VideoPreviews";
import Videos from "./pages/Videos";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Gate realm="login" />}>
                    <Route path="login" element={<Login />} />
                </Route>
                <Route element={<Gate realm="app" />}>
                    <Route element={<Layout />}>
                        <Route index element={<Navigate to="/dashboard" replace />} />
                        <Route path="dashboard" element={<Analytics />} />
                        <Route path="users" element={<Users />} />
                        <Route path="videos" element={<Videos />} />
                        <Route path="videos/:id/video" element={<EditVideo />} />
                        <Route path="videos/:id/previews" element={<VideoPreviews />} />
                        <Route path="models" element={<Models />} />
                        <Route path="categories" element={<Categories />} />
                        <Route path="images" element={<ImageSets />} />
                        <Route path="images/:id/files" element={<Images />} />
                        <Route path="plans" element={<Plans />} />
                        <Route path="banners" element={<Banners />} />
                        <Route path="pages" element={<StaticPages />} />
                        <Route path="pages/:slug" element={<StaticPagePage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
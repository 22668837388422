import { resourceDndSorting } from "@/js/dnd";
import { deleteResourceLinkedListItem } from "@/js/linkedList";
import { useVideoPreviews, useVideos } from "@/js/resources";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { getTitle } from "../common";
import VideoPreviewsComponent from "../components/pages/VideoPreviews";

const params = {with: ["translations"]};

export default function VideoPreviews() {
    const id = Number(useParams().id);
    const nextId = useRef(1);
    const [video] = useVideos({id, params});
    const [previews, {store, update, destroy}] = useVideoPreviews({ params: useMemo(() => ({video: id}), [id]) });
    const [uploads, setUploads] = useState<{
        id: number,
        filename: string,
        progress: number
    }[]>([]);

    const handleUpload = (files: File[]) => {
        for (const file of files) {
            const id = nextId.current++;
            setUploads(uploads => [...uploads, {
                id,
                filename: file.name,
                progress: 0
            }]);
            store({content: file}, "on-success", {
                onUploadProgress: e => {
                    const progress = e.progress;
                    if (progress !== undefined) {
                        setUploads(uploads => uploads.map(upload => upload.id === id ? {
                            ...upload,
                            progress
                        } : upload))
                    }
                }
            }).then(() => setUploads(uploads => uploads.filter(upload => upload.id !== id)));
        }
    }

    return (
        <VideoPreviewsComponent
            previews={previews}
            title={getTitle(video?.translations) ?? undefined}
            uploads={uploads}
            onDragDrop={resourceDndSorting(previews, update)}
            onDelete={id => deleteResourceLinkedListItem(previews, id, update, destroy)}
            onUpload={handleUpload}
        />
    )
}
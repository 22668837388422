import { requireNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { Outlet } from "react-router";
import { useUser } from "../providers/UserProvider";
import LayoutComponent from "./components/LayoutComponent";

export default function Layout() {
    const {user, update, logout} = useUser();

    return (
        <LayoutComponent className="grow" user={requireNotNull(user, "user must be logged in")} onLogout={logout} onUserUpdate={update}>
            <Outlet />
        </LayoutComponent>
    )
}
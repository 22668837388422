import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";

export interface FileInputProps {
    multiple?: boolean,
    accept?: string,
    onSelected: (files: File[]) => void
}

export interface FileInputRef {
    open: () => void
}

const FileInput = forwardRef<FileInputRef, FileInputProps>(({onSelected, ...props}, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        open: () => inputRef.current?.click()
    }), [inputRef]);

    const handleSelected = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
        if (e.target.files !== null) {
            onSelected([...e.target.files]);
        }
        e.target.value = "";
    }, [onSelected]);

    return (
        <input
            ref={inputRef}
            className="hidden"
            type="file"
            onChange={handleSelected}
            {...props}
        />
    )
});
export default FileInput;
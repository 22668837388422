import Loader from "@/js/components/Loader";
import { useGlissadeButton } from "@enymo/glissade";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";

export interface ButtonProps extends ClickableProps {
    loading?: boolean,
    variant?: "primary" | "secondary" | "danger",
    innerClassName?: string
}

export default function Button({
    className,
    innerClassName,
    variant,
    loading: loadingProp,
    disabled,
    onClick,
    submit,
    children,
    ...props
}: ButtonProps) {
    const {loading, ...glissade} = useGlissadeButton({disabled, loading: loadingProp, onClick, submit});

    return (
        <Clickable className={classNames("relative h-8.5 rounded-lg px-3 border text-sm", {
            "text-white fill-white border-primary-400 bg-primary-500 hover:bg-primary-600": variant === "primary",
            "text-text-800 fill-text-800 border-neutral-300 bg-bg-300 hover:bg-bg-400": variant === "secondary",
            "text-white fill-white border-error-300 bg-error-500 hover:bg-error-700": variant === "danger"
        }, className)} submit={submit} {...glissade} {...props}>
            <div className={classNames("size-full flex items-center justify-center", {"invisible": loading}, innerClassName)}>
                {children}
            </div>
            {loading && (
                <div className="absolute inset-1.5">
                    <Loader className="size-full" />
                </div>
            )}
        </Clickable>
    )
}
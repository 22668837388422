import { staticPageIds } from "@/js/resources";
import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../Breadcrumbs";
import DotsDropdown from "../DotsDropdown";
import Table from "../Table";

export default function StaticPages() {
    const {t} = useTranslation();

    return <>
        <Breadcrumbs breadcrumbs={[{
            children: t("pages")
        }]} />
        <div className="flex-1 overflow-auto">
            <Table
                head={[{
                    label: t("pages.slug")
                }, {
                    fill: true
                }]}
                rows={staticPageIds.map(id => ({
                    id,
                    data: [{
                        children: `/${id}`
                    }, {
                        children: (
                            <div className="flex justify-end">
                                <DotsDropdown items={[{
                                    to: `/pages/${id}`,
                                    children: t("pages.edit")
                                }]} />
                            </div>
                        )
                    }]
                }))}
            />
        </div>
    </>
}
import "@/css/admin.css";
import "@/js/common";
import de from "@/lng/admin/de.json";
import "dayjs/locale/de";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import "quill/dist/quill.snow.css";
import { initReactI18next } from "react-i18next";
import { Model } from "../resources";
import { Locale, locales } from "../types";

export function getTitle(translations?: Partial<Record<Locale, {
    title: string
}>>): string | null {
    const locale = translations && locales.find(locale => translations[locale]);
    return (locale && translations[locale]?.title) ?? null;
}

export function getDescription(translations: Model["translations"]): string | null {
    const locale = translations && locales.find(locale => translations[locale]);
    return (locale && translations[locale]?.description) ?? null;
}

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            convertDetectedLanguage: lng => {
                const index = lng.indexOf("-");
                return index === -1 ? lng : lng.substring(0, index);
            }
        },
        debug: import.meta.env.VITE_APP_DEBUG === "true",
        fallbackLng: "de",
        resources: {
            de: {
                translation: de
            }
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });
import XMarkIcon from "@/svg/xmark-regular.svg?react";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React, { useId } from "react";
import { RegisterOptions } from "react-hook-form";
import InputFrame from "./InputFrame";

export interface KeywordInputProps {
    className?: string,
    value?: string[],
    onChange?: (value: string[]) => void,
    name?: string,
    options?: RegisterOptions,
    label?: string,
    error?: string
}

export default function KeywordInput({
    className,
    value: externalValue,
    onChange: externalOnChange,
    name,
    options,
    label,
    error: errorProp
}: KeywordInputProps) {
    const id = useId();
    const {value, onChange, error} = useHybridInput({name, externalOnChange, externalValue, options, defaultValue: []});

    const handleRemove = (index: number) => () => {
        onChange(value.toSpliced(index, 1));
    }

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.key === "Enter" && e.currentTarget.value !== "") {
            onChange([...value, e.currentTarget.value]);
            e.currentTarget.value = "";
            e.preventDefault();
        }
    }

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = e => {
        if (e.currentTarget.value !== "") {
            onChange([...value, e.currentTarget.value]);
            e.currentTarget.value = "";
        }
    }

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        if (e.target.value.includes(",")) {
            onChange([...value, ...e.target.value.split(",").map(part => part.trim()).filter(part => part)]);
            e.target.value = "";
        }
    }
    
    return (
        <InputFrame id={id} className={className} label={label} error={errorProp ?? error?.message}>
            <div className={classNames(
                "px-2.5 py-2 rounded-md border bg-bg-100 flex flex-col gap-2",
                error ? "border-error-500" : "border-neutral-300 hover:border-neutral-400 focus:border-primary-500"
            )}>
                {value?.length > 0 && (
                    <div className="flex flex-wrap gap-1.5">
                        {value.map((value, index) => (
                            <div key={index} className="rounded-md h-5 bg-bg-700 px-1.5 flex items-center justify-center gap-1 text-2xs text-text-700">
                                {value}
                                <button type="button" onClick={handleRemove(index)}>
                                    <XMarkIcon className="w-1.5 fill-neutral-500" />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <input type="text" className="body-m" onChange={handleChange} onBlur={handleBlur} onKeyDown={handleKeyDown} />
            </div>
        </InputFrame>
    )
}
import CircleExclamationIcon from "@/svg/circle-exclamation-solid.svg?react";
import { useError } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";

export default function Error({className, noContext = false, children}: {
    className?: string,
    noContext?: boolean,
    children?: React.ReactNode
}) {
    const context = useError();

    return (children || (!noContext && context)) && (
        <div className={classNames("flex gap-2 items-center fill-error-500 text-xs text-error-500", className)}>
            <CircleExclamationIcon className="size-4" />
            {children ?? context}
        </div>
    )
}
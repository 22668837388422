import { useSanitizedString } from "@/js/admin/hooks/SanitizedParamHook";
import { useUsers } from "@/js/resources";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router";
import UsersComponent, { UsersSortBy } from "../components/pages/Users";
import usePaginator from "../hooks/PaginatorHook";



export default function Users() {
    const {page, q, search, changePage, sortBy, changeSortBy} = usePaginator<UsersSortBy["name"]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const status = useSanitizedString(searchParams.get("status"), ["active", "inactive", "cancelled", "downgrade"], null);
    const role = useSanitizedString(searchParams.get("role"), ["admin", "permanentAccess", "user"], null);
    const [users, {update, destroy, meta}] = useUsers({
        params: useMemo(() => ({
            page,
            q,
            sort_by: sortBy ?? {name: "id", direction: "desc"},
            status: status,
            role: role
        }), [page, q, sortBy, status, role])
    })

    const handleUpdateSearchParams = (param: string) => (value: string | null) => {
        if (value) {
            searchParams.set(param, value);
        }
        else {
            searchParams.delete(param);
        }
        setSearchParams(searchParams);
    }

    if (meta === null) {
        return null;
    }

    return (
        <UsersComponent
            users={users}
            itemsPerPage={meta.per_page}
            totalItems={meta.total}
            page={page}
            onChangePage={changePage}
            onSearch={search}
            onUserDelete={destroy}
            onUserUpdate={update}
            sortBy={sortBy ?? {name: "id", direction: "desc"}}
            onChangeSortBy={changeSortBy}
            filterStatus={status}
            filterRole={role}
            onChangeFilterRole={handleUpdateSearchParams("role")}
            onChangeFilterStatus={handleUpdateSearchParams("status")}
        />
    )
}
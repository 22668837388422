import { StaticPageId, staticPageIds, useStaticPages } from "@/js/resources";
import React from "react";
import { Navigate, useParams } from "react-router";
import StaticPageComponent from "../components/pages/StaticPageComponent";

export default function StaticPagePage() {
    const {slug} = useParams();

    if (!slug || !staticPageIds.includes(slug as any)) {
        return <Navigate to="/pages" replace />
    }

    const [page, {update}] = useStaticPages({id: slug as StaticPageId});

    return (
        <StaticPageComponent
            slug={slug}
            translations={page?.translations}
            onUpdate={update}
        />
    )
}
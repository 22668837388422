import { useVideoResolutions, useVideos } from "@/js/resources";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { getTitle } from "../common";
import EditVideoComponent from "../components/pages/EditVideo";
import useAbortSignal from "../hooks/AbortSignalHook";

const params = {
    with: ["translations"]
};

export default function EditVideo() {
    const id = Number(useParams().id);
    const [video] = useVideos({id, params});
    const [resolutions, {store}] = useVideoResolutions({params: useMemo(() => ({
        video: id
    }), [id])});
    const [uploadSize, setUploadSize] = useState<number | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const signal = useAbortSignal();

    const handleUpload = async (file: File) => {
        setUploadSize(file.size);
        await store({file}, "on-success", {
            onUploadProgress: e => {
                if (e.progress !== undefined) {
                    setUploadProgress(e.progress);
                }
            },
            signal
        });
        setUploadProgress(null);
        setUploadSize(null);
    }

    return (
        <EditVideoComponent
            title={getTitle(video?.translations) ?? undefined}
            resolutions={resolutions}
            upload={uploadSize !== null && uploadProgress !== null ? {
                progress: uploadProgress,
                size: uploadSize
            } : undefined}
            onUpload={handleUpload}
        />
    )
}
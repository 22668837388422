import { useUser } from "@/js/providers/UserProvider";
import { FormError } from "@enymo/react-form-component";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import LoginComponent, { Submit } from "../components/pages/Login";

export default function Login() {
    const {t} = useTranslation();
    const {login} = useUser();
    const navigate = useNavigate();

    const handleLogin = async ({email, password}: Submit) => {
        const user = await login(email, password, true);
        if (user.role === "admin") {
            navigate("/dashboard");
        }
        else {
            throw new FormError({
                email: t("login.noAdmin")
            });
        }
    }

    return (
        <LoginComponent className="grow" onLogin={handleLogin} />
    )
}
import React from "react";
import { Navigate, Outlet } from "react-router";
import { useUser } from "../providers/UserProvider";

export default function Gate({realm}: {
    realm: "login" | "app"
}) {
    const {user, loading} = useUser();

    if (loading) {
        return null;
    }
    else if (realm === "login" && user !== null && user.role === "admin") {
        return <Navigate to="/dashboard" replace />
    }
    else if (realm === "app" && (user === null || user.role !== "admin")) {
        return <Navigate to="/login" replace />
    }
    else {
        return <Outlet />
    }
}
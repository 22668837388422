import Quill, { QuillOptions } from "quill";
import "quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";

export default function useQuill<T extends HTMLElement>(options?: QuillOptions) {
    const ref = useRef<T>(null);
    const [quill, setQuill] = useState<Quill>();

    useEffect(() => {
        const target = ref.current;
        if (target) {
            const container = target.ownerDocument.createElement("div");
            container.style.flex = "1";
            setQuill(new Quill(target.appendChild(container), options));
            return () => {
                while (target.lastElementChild !== null) {
                    target.lastElementChild.remove();
                }
            };
        }
    }, [ref, setQuill]);

    return <const>[quill, ref];
}
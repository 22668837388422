import { returnFalse } from "@/js/common";
import { ImageSetUpdate, useCategories, useImageSets, useModels } from "@/js/resources";
import { status } from "@/js/types";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router";
import { route } from "ziggy-js";
import ImageSetsComponent, { ImageSetsSortBy } from "../components/pages/ImageSets";
import usePaginator from "../hooks/PaginatorHook";
import { useSanitizedNumber, useSanitizedNumbers, useSanitizedString } from "../hooks/SanitizedParamHook";

const imageSetParams = {
    with: ["translations", "category", "models", "featured_preview", "unpublished"]
}

const categoryParams = {
    locale: "de"
}

export default function ImageSets() {
    const {page, sortBy, changeSortBy, changePage, search, q} = usePaginator<ImageSetsSortBy["name"]>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories] = useCategories({params: categoryParams});
    const [models] = useModels();

    const filterModels = useSanitizedNumbers(searchParams.getAll("models[]"), {min: 0}, undefined, [searchParams]);
    const filterCategory = useSanitizedNumber(searchParams.get("category"), {min: 0}, null);
    const filterStatus = useSanitizedString(searchParams.get("status"), status.filter(status => status !== "error" && status !== "processing"), null);
    console.log(route);

    const [imageSets, {store, update, destroy, refresh, meta}] = useImageSets({
        onCreated: returnFalse,
        params: useMemo(() => ({
            with: ["translations", "category", "models", "featured_preview", "unpublished"],
            sort_by: sortBy ?? {name: "id", direction: "desc"},
            page,
            q,
            models: filterModels,
            category: filterCategory,
            status: filterStatus
        }), [q, page, sortBy, filterModels, filterCategory, filterStatus])
    });

    const handleUpdateSearchParams = (param: string) => (value: string | number | number[] | null) => {
        if (value === null) {
            searchParams.delete(param);
        }
        else if (Array.isArray(value)) {
            searchParams.delete(`${param}[]`);
            for (const single of value) {
                searchParams.append(`${param}[]`, single.toString());
            }
        }
        else {
            searchParams.set(param, value.toString());
        }
        setSearchParams(searchParams);
    }

    const handleStore = async (data: Omit<ImageSetUpdate, "view" | "favorite" | "watch_later" | "rating">) => {
        await store(data);
        await refresh();
    }

    if (meta === null) {
        return null;
    }

    return (
        <ImageSetsComponent
            itemsPerPage={meta.per_page}
            page={page}
            onChangePage={changePage}
            sortBy={sortBy ?? {name: "id", direction: "desc"}}
            onChangeSortBy={changeSortBy}
            onImageSetCreate={handleStore}
            onImageSetUpdate={update}
            onImageSetDelete={destroy}
            onSearch={search}
            totalItems={meta.total}
            categories={categories}
            models={models}
            imageSets={imageSets}
            filterCategory={filterCategory}
            filterModels={filterModels}
            filterStatus={filterStatus}
            onChangeFilterCategory={handleUpdateSearchParams("category")}
            onChangeFilterModels={handleUpdateSearchParams("models")}
            onChangeFilterStatus={handleUpdateSearchParams("status")}
        />
    )
}